import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../core/interceptors/api.service';
import { CommonType } from '../Models/Customer';
import { Months } from '../Enum/Months.enum';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public mainPerimeter = [];
  private venueDetails: any;
  Days: CommonType[] = []
  Months: CommonType[] = []
  Years: CommonType[] = []

  constructor(private _apiService: ApiService) { }
  private activeStepsSubject = new BehaviorSubject<number[]>([]);
  activeSteps$ = this.activeStepsSubject.asObservable();

  setActiveSteps(steps: number[]) {
    this.activeStepsSubject.next(steps);
  }

  setVenueDetails(details: any) {
    this.venueDetails = details;
  }

  getVenueDetailsData() {
    return this.venueDetails;
  }

  SetMainPerimeter(perimeter: any[]) {
    localStorage.setItem("perimeter", JSON.stringify(perimeter));
  }

  RemoveMainPerimeter(key = "perimeter") {
    localStorage.removeItem(key);
  }

  GetMainPerimeter() {
    let obj = localStorage.getItem("perimeter");
    return JSON.parse(obj);
  }

  GetVenueDetails(id: string) {
    const url = `/Venue/${id}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  CreatePointArrayForPolygon(path: any) {
    let points: { lat: number; lng: number }[] = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      points.push(path.getAt(i).toJSON());
    }
    return points;
  }

  numberToWord(n) {
    const words = [
      'Ground', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth',
      'Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth',
      'Twentieth',
    ];

    return words[n];
  }

  getDays() {
    let tottalDay = 31
    for (let index = 1; index <= tottalDay; index++) {
      this.Days.push({ 'id': index, 'name': index.toString() });
    }

    return this.Days;
  }
  getMonths() {
    let tottalMonth = 12
    for (let index = 1; index <= tottalMonth; index++) {
      this.Months.push({ 'id': index, 'name': this.GetMonthNameFromMonthIndex(index) });
    }
    return this.Months;
  }
  getYears() {
    let currentDate = new Date();
    currentDate.setFullYear(2018)
    let tottalYear = Number(currentDate.getFullYear()) + 20
    for (let index = 2024; index <= tottalYear; index++) {
      this.Years.push({ 'id': index, 'name': index.toString() });
    }
    return this.Years;
  }

  GetMonthNameFromMonthIndex(monthIndex: number) {
    const monthNames = Object.keys(Months).filter((key) => isNaN(Number(key))) as Array<keyof typeof Months>;
    const month = monthNames.find((month) => Months[month] === monthIndex);

    if (month) {
      return month;
    }

    return '';
  }

  GetDateAndTime(checkYear: string, checkMonth: string, checkDay: string, checkTime: string) {
    const date = `${checkYear}-${checkMonth}-${checkDay}`;
    const [hours, minutes] = checkTime.split(":");
    const time = `${hours}:${minutes}:00.000`;
    const dateTime = `${date}T${time}Z`;
    return dateTime
  }

  getDateFormet(dateString: string) {
    const datePart = dateString.split("T")[0];
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate
  }

  isDateInFuture(inputDate: string) {
    const currentDate = new Date();
    const dateToCheck = new Date(inputDate);
    return dateToCheck > currentDate;
  }

  getFutureDate(interval: string, date: string): string {
    const parts = date.split('T')[0].split('-');
    if (parts.length === 3) {
      const year = parts[0];
      const month = String(Number(parts[1])).padStart(2, '0');
      const day = String(Number(parts[2])).padStart(2, '0');
      date = `${year}-${month}-${day}T${date.split('T')[1]}`;
    }

    let futureDate = new Date(date);

    if (isNaN(futureDate.getTime())) {
      throw new Error('Invalid date provided. Please ensure the date is in a valid format.');
    }

    switch (interval.toLowerCase()) {
      case 'yearly':
        futureDate.setFullYear(futureDate.getFullYear() + 1);
        break;
      case 'monthly':
        futureDate.setMonth(futureDate.getMonth() + 1);
        break;
      case 'weekly':
        futureDate.setDate(futureDate.getDate() + 7);
        break;
      case 'quarterly':
        futureDate.setMonth(futureDate.getMonth() + 3);
        break;
      case 'sixmonthly':
        futureDate.setMonth(futureDate.getMonth() + 6);
        break;
      default:
        throw new Error('Invalid interval. Please use "yearly", "monthly", "quarterly", "sixmonthly" or "weekly".');
    }

    const formattedDate = futureDate.toISOString().slice(0, 19);
    return this.getDateFormet(formattedDate);
  }
}
