import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiErrorService } from "src/app/core/services/api-error.service";
import { AssetsListService } from "src/app/customer/components/assets-list/assets-list.service";
import { CustomerService } from "src/app/customer/customer.service";
import { AssetsDetails, ChecksDetails, CommonType } from "src/app/Models/Customer";

@Component({
  selector: 'app-individual-check-form',
  templateUrl: './individual-check-form.component.html',
  styleUrls: ['./individual-check-form.component.css']
})
export class IndividualCheckFormComponent implements OnInit {
  individualCheckForm: FormGroup;
  venueId: string;
  assetId: string;
  communicationMethodId: string;
  allData: AssetsDetails
  isIndividualCheckSend: boolean = false;

  Days: CommonType[] = [];
  Months: CommonType[] = [];
  Years: CommonType[] = [];
  futureDate: string;
  frequency: string;

  constructor(
    private _dialogRef: MatDialogRef<IndividualCheckFormComponent>,
    private _customerService: CustomerService,
    private _apiErrorService: ApiErrorService,
    private _assetsListService: AssetsListService,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.allData = this._initialInfo.dialogInfo.allInfo
    this.venueId = this._initialInfo.dialogInfo.venueId
    this.assetId = this._initialInfo.dialogInfo.allInfo.id
    this.frequency = this._initialInfo.dialogInfo.allInfo.assetCheckFrequencyDisplay
    this.InitForm();
    this.getDays();
    this.getMonths();
    this.getYears();

    let currentDate = new Date();
    let Hours = currentDate.getHours();
    let Time;
    let incidentDateMintes = currentDate.getMinutes().toString();
    if (incidentDateMintes.length == 1) {
      incidentDateMintes = "0" + incidentDateMintes;
    }
    if (Hours < 10) {
      if (currentDate.getHours() > 0) {
        Time =
          "0" +
          Number(Number(currentDate.getHours())) +
          ":" +
          incidentDateMintes;
      } else {
        Time =
          "0" + Number(currentDate.getHours()) + ":" + incidentDateMintes;
      }
    } else {
      Time =
        Number(Number(currentDate.getHours())) + ":" + incidentDateMintes;
    }
    this.individualCheckForm.get('checkTime')?.setValue(Time);
    this.individualCheckForm.get('checkDay')?.setValue(currentDate.getDate().toString());
    this.individualCheckForm.get('checkMonth')?.setValue(Number(
      currentDate.getMonth() + 1
    ).toString());
    this.individualCheckForm.get('checkYear')?.setValue(currentDate.getFullYear().toString());

    this.futureDateFunction(null, null, null)

    this.individualCheckForm.get('checkDay')?.valueChanges.subscribe(value => {
      this.futureDateFunction(value, null, null)
    });

    this.individualCheckForm.get('checkMonth')?.valueChanges.subscribe(value => {
      this.futureDateFunction(null, value, null)
    });

    this.individualCheckForm.get('checkYear')?.valueChanges.subscribe(value => {
      this.futureDateFunction(null, null, value)
    });
  }

  InitForm() {
    this.individualCheckForm = this.fb.group({
      checkDay: ['', Validators.required],
      checkMonth: ['', Validators.required],
      checkYear: ['', Validators.required],
      checkTime: ['', Validators.required],
      notes: ['', Validators.required],
      actions: this.fb.array([
        this.fb.group({
          action: ['', Validators.required],
          urgency: ['', Validators.required]
        })
      ])
    });
  }

  getDays() {
    this.Days = this._customerService.getDays();
  }

  getMonths() {
    this.Months = this._customerService.getMonths();
  }

  getYears() {
    this.Years = this._customerService.getYears();
  }

  get checkDay() {
    return this.individualCheckForm.get("checkDay") as FormControl;
  }

  get checkMonth() {
    return this.individualCheckForm.get("checkMonth") as FormControl;
  }

  get checkYear() {
    return this.individualCheckForm.get("checkYear") as FormControl;
  }

  get checkTime() {
    return this.individualCheckForm.get("checkTime") as FormControl;
  }

  get notes() {
    return this.individualCheckForm.get("notes") as FormControl;
  }

  get actions() {
    return this.individualCheckForm.get("actions") as FormArray;
  }

  getFutureDate(interval: string, date: string) {
    return this._customerService.getFutureDate(interval, date);
  }

  futureDateFunction(dayValue: string | null, monthValue: string | null, yearValue: string | null): void {
    const { checkYear, checkMonth, checkDay, checkTime } = this.individualCheckForm.value;
    const day = dayValue === null ? checkDay : dayValue;
    const month = monthValue === null ? checkMonth : monthValue;
    const year = yearValue === null ? checkYear : yearValue;

    if (checkTime) {
      const dateTime = this._customerService.GetDateAndTime(year, month, day, checkTime);
      const futureDate = this._customerService.getFutureDate(this.frequency, dateTime);
      this.futureDate = futureDate;
    } else {
      console.log("Check time is not defined.");
    }
  }

  resetForm(form: FormGroup, defaultValues?: any) {
    if (defaultValues) {
      form.reset(defaultValues);
    } else {
      form.reset();
    }
  }

  onSubmitIndividualCheck() {
    if (this.individualCheckForm.invalid) {
      this.individualCheckForm.markAllAsTouched();
      return false;
    }

    this.isIndividualCheckSend = true;

    const { checkYear, checkMonth, checkDay, checkTime, notes, actions } = this.individualCheckForm.value
    const dateTime = this._customerService.GetDateAndTime(checkYear, checkMonth, checkDay, checkTime);

    let payload = {
      id: this.venueId,
      dateTime: dateTime,
      notes: notes,
      actions: actions,
    } as ChecksDetails

    this._assetsListService.AddIndividualCheckDetails(payload, this.venueId, this.assetId).subscribe(
      (response) => {
        if (response) {
          this.resetForm(this.individualCheckForm);
          this.isIndividualCheckSend = false;
          this._dialogRef.close(payload);
        }
      },
      (error) => {
        this._apiErrorService.ShowError(error);
        this.isIndividualCheckSend = false;
      }
    );
  }

  addNewActions() {
    (this.individualCheckForm.get('actions') as FormArray).push(
      this.fb.group({
        action: ['', Validators.required],
        urgency: ['', Validators.required]
      })
    );
  }

  removeAction(index: number) {
    (this.individualCheckForm.get('actions') as FormArray).removeAt(index);
  }
}


