import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/interceptors/api.service';
import { map } from 'rxjs/operators';
import { ChecksDetails } from 'src/app/Models/Customer';

@Injectable({
  providedIn: 'root'
})
export class AssetsListService {

  constructor(private _apiService: ApiService) { }

  AddIndividualCheckDetails(details: ChecksDetails, venueId: string, assetId: string) {
    const url = `/Venue/Assets/Checks/Add/${venueId}/${assetId}`;
    return this._apiService.post(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }

  AddBulkCheckDetails(details: ChecksDetails, venueId: string) {
    const url = `/Venue/Assets/Checks/BulkAdd/${venueId}`;
    return this._apiService.post(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }

  EditCheckDetails(details: ChecksDetails, venueId: string,  assetId: string,  assetCheckId: string) {
    const url = `/Venue/Assets/Checks/Edit/${venueId}/${assetId}/${assetCheckId}`;
    return this._apiService.put(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }

  EditActionDetails(details: ChecksDetails, venueId: string,  assetId: string,  assetActionId: string) {
    const url = `/Venue/Assets/Actions/Edit/${venueId}/${assetId}/${assetActionId}`;
    return this._apiService.put(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }

  CompleteActionDetails(details: ChecksDetails, venueId: string,  assetId: string,  assetActionId: string) {
    const url = `/Venue/Assets/Actions/FlagAsCompleted/${venueId}/${assetId}/${assetActionId}`;
    return this._apiService.put(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
