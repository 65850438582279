import { Member } from './../Members/Member';
import { MessageDeliveryChannelEnum } from "src/app/Enum/MessageDeliveryChannelEnum.enum";
import { MemberType } from 'src/app/Enum/MemberType.enum';
import { MessageTemplateStatusEnum } from 'src/app/Enum/MessageTemplateStatusEnum.enum';
import { BCURegionDTO } from '../BCURegionDTO.Model';
import { VenueDTO } from '../Customer';

export class MessageTemplateDTO {

  id: string;
  date: Date;
  subject: string;
  message: string;
  incident: MessageIncidentDTO;
  deliveryChannel: MessageDeliveryChannelEnum;
  from: MemberProfileDetailsDTO;
  status: MessageTemplateStatusEnum;

}


export class MessageIncidentDTO{
  id: string;
  title: string;
  address: string;
}


export class MemberProfileDetailsDTO extends Member{
   memberCategory :MemberCategoryDTO
   groups :OrganizationGroupDTO[];
   bcuRegion: BCURegionDTO;
  venues?: VenueDTO
  profile?: any
}

export class MemberCategoryDTO{
  id: number;
  label: string;
  memberType: MemberType;
} 

export class OrganizationGroupDTO{
  id: number;
  groupName: string;
  isDefault: boolean;
  organizationId: number;
  organizationName: string;
  organizationLogo: string;
  organizationGroupLogo: string;
  regionId: string;
  regionName: string;
  regionDescription: string
}

export class MemberForMessage{
  name: string; 
  memberId: string;
  avatar: string;
  jobTitle: string
}
