import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { GooglemapService } from 'src/app/core/services/googlemap.service';
import { AssetsDetails, GetAssetsParkingDTO } from 'src/app/Models/Customer';
import { AssetsService } from 'src/app/customer/components/assets/assets.service';

@Component({
  selector: 'app-parking-edit-form',
  templateUrl: './parking-edit-form.component.html',
  styleUrls: ['./parking-edit-form.component.css']
})
export class ParkingEditFormComponent implements OnInit {
  venueId: string;
  assetId: string;
  lng: number;
  lat: number;
  zoom: number = 18;
  strokeColor = "#80cff4"
  isParkingMapRequired: boolean = false;
  isParkingSend: boolean = false;
  parkingOptions = [];
  parkingTabDetails = [];
  mainPerimeter = [];
  parkingTabName: string;
  assetsParkingForm: FormGroup;
  GetAssetsParkingInfo = new GetAssetsParkingDTO();

  constructor(
    private _dialogRef: MatDialogRef<ParkingEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private _apiErrorService: ApiErrorService,
    private _googleMapService: GooglemapService,
    private _assetsService: AssetsService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.InitForm();
    this.parkingTabDetails = this._initialInfo.dialogInfo.allInfo.area
    this.lat = this._initialInfo.dialogInfo.lat
    this.lng = this._initialInfo.dialogInfo.lng
    this.mainPerimeter = this._initialInfo.dialogInfo.mainPerimeter
    this.assetsParkingForm.get('parkingType')?.setValue(this._initialInfo.dialogInfo.allInfo.type);
    this.assetsParkingForm.get('parkingName')?.setValue(this._initialInfo.dialogInfo.allInfo.name);
    this.assetsParkingForm.get('parkingDescription')?.setValue(this._initialInfo.dialogInfo.allInfo.description);
    this.assetsParkingForm.get('frequency')?.setValue(this._initialInfo.dialogInfo.allInfo.assetCheckFrequency);
    this.parkingOptions = this._initialInfo.dialogInfo.parkingOptions
    this.venueId =  this._initialInfo.dialogInfo.venueId
    this.assetId =  this._initialInfo.dialogInfo.allInfo.id
    this.parkingTabName = this._initialInfo.dialogInfo.allInfo.name
  }

  InitForm() {
    this.assetsParkingForm = this.fb.group({
      parkingType: [null, Validators.required],
      parkingName: [null, Validators.required],
      parkingDescription: [null, Validators.required],
      frequency: [null, Validators.required],
    });
  }

  get parkingType() {
    return this.assetsParkingForm.get("parkingType") as FormControl;
  }

  get parkingName() {
    return this.assetsParkingForm.get("parkingName") as FormControl;
  }

  get parkingDescription() {
    return this.assetsParkingForm.get("parkingDescription") as FormControl;
  }

  get frequency() {
    return this.assetsParkingForm.get("frequency") as FormControl;
  }

  resetForm(form: FormGroup, defaultValues?: any) {
    if (defaultValues) {
      form.reset(defaultValues);
    } else {
      form.reset();
    }
  }

  commanParkingFormSubmission() {
    this.isParkingSend =true;
      const newData = {
        localityPosition: [...this.GetAssetsParkingInfo?.localityPosition, ...this.parkingTabDetails],
        ...this.assetsParkingForm.value
      };

      const payload = {
        id: this.venueId,
        category: 3,
        type: parseInt(newData.parkingType),
        description: newData.parkingDescription,
        area: newData.localityPosition,
        numberFloors: 0,
        hasPublicAccess: false,
        name: newData.parkingName,
        assetCheckFrequency: parseInt(newData.frequency),
      } as AssetsDetails
      
      this._assetsService.UpdateAssetsTabsDetails(payload, this.venueId, this.assetId).subscribe(
        (response) => {
          if (response) {
            this.resetForm(this.assetsParkingForm);
            this.isParkingSend = false;
            this._dialogRef.close(this.assetId);
          }
        },
        (error) => {
          this._apiErrorService.ShowError(error);
          this.isParkingSend = false;
        }
      );

    return true;
  }

  onSubmitAssetsParking() {
    if (this.assetsParkingForm.invalid || (this.parkingTabDetails.length === 0 && !this.GetAssetsParkingInfo?.localityPosition?.length)) {
      this.assetsParkingForm.markAllAsTouched();
      this.isParkingMapRequired = !this.GetAssetsParkingInfo?.localityPosition?.length;
      return false;
    }

    this.isParkingMapRequired = false;
    if (!this.commanParkingFormSubmission()) {
      return;
    }
  }

  OnMapReadyForParking = (map: any) => {
    this._googleMapService.OverlayCompletehandler(map, null, (path) => {
      if (this.GetAssetsParkingInfo.localityPosition == null) {
        this.GetAssetsParkingInfo.localityPosition = [];
      }
      this.GetAssetsParkingInfo.localityPosition.push(this._assetsService.CreatePointArrayForPolygon(path));
    });
  };

  RemoveParkingPolygon() {
    this.parkingTabDetails = []
    this.GetAssetsParkingInfo.localityMapReinitial = true;
    this.GetAssetsParkingInfo.localityPosition = [];
    setTimeout(() => {
      this.GetAssetsParkingInfo.localityMapReinitial = false;
    }, 10);
  }
}

