import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LocationType } from 'src/app/Enum/LocationType.enum';
import { LocationDTO } from 'src/app/Models/incident.modal';
import { ApiService } from 'src/app/core/interceptors/api.service';

@Injectable({
  providedIn: 'root'
})
export class RoomAssetsAddEditFormService {

  constructor(private _apiService: ApiService) {}

  saveUpdateLocation(LocationSectionDTO: LocationDTO, incidentId: string) {
    const url = `/BCU/Location/Map/Save/${incidentId}`;
    return this._apiService.post(url, LocationSectionDTO).pipe(
      map((data) => {
        return data;
      })
    );
  }


  LocationTypeDesider(locationType : string) : number{
    let result : number;
    switch (locationType) {
      case LocationType.PoliceCordonLocation:
        result = 0;
        break;

        case LocationType.RendezvousPointLocation:
        result = 1;
        break;

        case LocationType.CrimeSceneLocation:
        result = 2;
        break;

      default:
        result = 0;
    }

    return result;
  }

  CreatePointArrayForPolygon(path: any){
    let points : { lat: number; lng: number }[] = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      points.push(path.getAt(i).toJSON());
    }
    return points;
  }
}
