<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="custom-container">
                <div class="form-style w-400 ms-auto me-auto">
                    <form [formGroup]="individualCheckForm" (ngSubmit)="onSubmitIndividualCheck()"
                        class="individual-check-form">
                        <div class="fieldset">
                            <div class="heading-section">{{allData.categoryName}} check</div>
                            <div>
                                <div class="hs-input">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="select-style">
                                                <select class="form-control" formControlName="checkDay" required>
                                                    <option [value]="day.id" *ngFor="let day of Days">{{day.name}}
                                                    </option>
                                                </select>
                                                <span class="text-danger text-error-message"
                                                    *ngIf="checkDay.touched && checkDay.hasError('required')">
                                                    Day is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="select-style">
                                                <select class="form-control" formControlName="checkMonth" required>
                                                    <option [value]="month.id" *ngFor="let month of
                                              Months">{{month.name}}</option>
                                                </select>
                                                <span class="text-danger text-error-message"
                                                    *ngIf="checkMonth.touched && checkMonth.hasError('required')">
                                                    Month is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="select-style">
                                                <select class="form-control" formControlName="checkYear" required>
                                                    <option [value]="year.id" *ngFor="let year of Years">{{year.name}}
                                                    </option>
                                                </select>
                                                <span class="text-danger text-error-message"
                                                    *ngIf="checkYear.touched && checkYear.hasError('required')">
                                                    Year is required
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="hs-input time-input">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <input type="time" class="form-control" formControlName="checkTime" required>
                                            <span class="text-danger text-error-message"
                                                *ngIf="checkTime.touched && checkTime.hasError('required')">
                                                Time is required
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-4">
                                <textarea formControlName="notes" class="form-control textarea-box" rows="3"
                                    placeholder="Notes" required></textarea>
                                <span class="text-danger text-error-message"
                                    *ngIf="notes.touched && notes.hasError('required')">
                                    Notes is required
                                </span>
                            </div>
                            <div class="mb-1 d-flex">
                                <div class="d-flex flex-column w-100">
                                    <div formArrayName="actions">
                                        <div *ngFor="let action of actions.controls; let i = index" class="mb-1">
                                            <div class="d-flex w-100" [formGroupName]="i">
                                                <div class="d-flex w-100 action-list">
                                                    <div class="w-100">
                                                        <textarea formControlName="action"
                                                            class="form-control textarea-box" rows="3"
                                                            placeholder="Actions" required></textarea>
                                                        <span class="text-danger text-error-message"
                                                            *ngIf="actions.controls[i].get('action').touched && actions.controls[i].get('action').hasError('required')">
                                                            Actions is required
                                                        </span>
                                                    </div>
                                                    <div class="urgency">
                                                        <div class="title">
                                                            Urgency
                                                        </div>
                                                        <div>
                                                            <input type="radio" id="advisory" formControlName="urgency"
                                                                [value]="1" class="mr-1 showHand">
                                                            <label class="Comman-circle bg-orrange showHand"
                                                                for="advisory"></label>
                                                            <input type="radio" id="bad" formControlName="urgency"
                                                                [value]="0" class="mr-1 showHand">
                                                            <label class="Comman-circle bg-red showHand"
                                                                for="bad"></label>
                                                        </div>
                                                        <span class="text-danger text-error-message"
                                                            *ngIf="actions.controls[i].get('urgency').touched && actions.controls[i].get('urgency').hasError('required')">
                                                            Urgency is required
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <div *ngIf="i !== 0">
                                                    <img class="showHand" (click)="removeAction(i)"
                                                        src="../../../assets/images/delete-icon.png">
                                                </div> -->
                                                <!-- <div *ngIf="i === 0" class="plus-btn">
                                                    <img class="showHand" (click)="addNewActions()"
                                                        src="../../../assets/images/plus-black.png">
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="showHand" (click)="addNewActions()">Add an action</div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="next-btn mb-3">
                            <div class="d-flex flex-column justify-content-between">
                                <div class="hs-submit text-start">
                                    <button type="submit" class="primary-btn">
                                        <div *ngIf="!isIndividualCheckSend">
                                            Submit
                                        </div>
                                        <div *ngIf="isIndividualCheckSend"
                                            class="d-flex align-items-center justify-content-center">
                                            <mat-spinner class="custom-button-spinner" diameter="20"></mat-spinner>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="rechecking-date mb-1">
                            Needs re-checking by <span>{{futureDate}}</span>
                        </div>
                        <div class="user-name">
                            Pete Dickenson <span>21/4/24 </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>