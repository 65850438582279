<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Sign up</title>
</head>

<body>
    <div class="container">
        <div class="d-flex flex-column flex-md-row">
            <div class="header-logo d-flex d-md-none justify-content-center align-content-center">
                <img src="../../assets/images/sim-logo1.png" alt="sim-logo">
            </div>
            <div>
                <ol class="stepper">
                    <li class="stepper-item complete">
                        <span class="stepper-counter"></span>
                        <span>Create account</span>
                        <span class="stepper-line"></span>
                    </li>

                    <li class="stepper-item" [ngClass]="{'complete': isVerify}">
                        <span class="stepper-counter"></span>
                        <span>Verify account</span>
                        <span class="stepper-line"></span>
                    </li>
                </ol>
            </div>
            <div class="w-100">
                <div class="header-logo d-none d-md-flex justify-content-center align-content-center">
                    <img src="../../assets/images/sim-logo2.png" alt="sim-logo">
                </div>

                <!-- customer sign up form -->
                <div *ngIf="showUserSignUpForm">
                    <div class="subheading">Create your account</div>
                    <form [formGroup]="customerSignUpForm" (ngSubmit)="onSubmitUserDetails()"
                        class="customer-signup-form">
                        <div class="row">
                            <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                                <input type="text" id="firstName" formControlName="firstName" placeholder="First name"
                                    class="">
                                <span class="text-danger text-error-message"
                                    *ngIf="firstName.touched && firstName.hasError('required')">
                                    First name is required
                                </span>
                            </div>
                            <div class="col-12 col-sm-6">
                                <input type="text" id="lastName" formControlName="lastName" placeholder="Last name">
                                <span class="text-danger text-error-message"
                                    *ngIf="lastName.touched && lastName.hasError('required')">
                                    Last name is required
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <input type="text" id="organisation" formControlName="organisation"
                                    placeholder="Organization">
                                <span class="text-danger text-error-message"
                                    *ngIf="organisation.touched && organisation.hasError('required')">Organization is
                                    required.
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <input type="email" id="email" formControlName="email" placeholder="Email">
                                <span class="text-danger text-error-message"
                                    *ngIf="email.touched && email.hasError('required')">Email
                                    is
                                    required.</span>
                                <span class="text-danger text-error-message"
                                    *ngIf="email.touched && email.hasError('email')">Invalid
                                    email
                                    format.</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex custom-number-feild">
                                    <div class="flag"><img src="../../assets/images/nation-flag.png" alt="sim-logo">
                                    </div>
                                    <div class="d-flex w-100">
                                        <span class="prefix">+44</span>
                                        <input type="text" id="mobileNumber" formControlName="mobileNumber">
                                    </div>
                                </div>
                                <span class="text-danger text-error-message"
                                    *ngIf="mobileNumber.touched && mobileNumber.hasError('required')">Phone number is
                                    required.
                                </span>
                                <span class="text-danger text-error-message"
                                    *ngIf="mobileNumber.touched && (mobileNumber.hasError('pattern') || mobileNumber.hasError('maxlength') || mobileNumber.hasError('minlength'))">
                                    Invalid mobile number
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <input type="password" id="password" formControlName="password" placeholder="Password">
                                <span class="text-danger text-error-message"
                                    *ngIf="password.touched && password.hasError('required')">Password is required.
                                </span>
                                <span class="text-danger text-error-message"
                                    *ngIf="password.touched && password.hasError('minlength')">Password must be at least
                                    8 characters
                                    long.
                                </span>
                            </div>
                        </div>
                        <div class="checkbox">
                            <div class="checkbox-info">
                                <input type="checkbox" id="tickedTerms" formControlName="tickedTerms">
                                <label for="tickedTerms">By signing up, you agree to our <a href="#">Terms and
                                        conditions</a> and <a href="#">Privacy
                                        Policy</a>.</label>
                            </div>
                            <span class="text-danger text-error-message"
                                *ngIf="tickedTerms.touched && tickedTerms.hasError('required')">You must agree to the
                                terms and
                                conditions.
                            </span>
                        </div>
                        <div class="row" *ngIf="errorMessage">
                            <div class="text-danger">{{ errorMessage }}</div>
                        </div>
                        <button type="submit">Save</button>
                    </form>
                </div>

                <!-- Verify your account form -->
                <div *ngIf="sawVerifyForm">
                    <div class="subheading">Verify your account</div>
                    <form [formGroup]="customerVerifyForm" (ngSubmit)="onSubmitEmailVerificationDetails()"
                        class="customer-verify-form">
                        <div class="form-group">
                            <div class="description">A code has been sent to your email.</div>
                        </div>
                        <div class="form-group email">
                            <div>{{email?.value}}</div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <input type="text" id="code" formControlName="code" placeholder="Code">
                                <span class="text-danger text-error-message"
                                    *ngIf="code.touched && code.hasError('required')">Code is
                                    required.
                                </span>
                            </div>
                        </div>
                        <div class="d-flex description">Not received?</div>
                        <button mat-raised-button class="green-button" (click)="onResendConfirmationCode()">Send another
                            code</button>
                        <div class="row" *ngIf="errorMessageConfirm">
                            <div class="text-danger">{{ errorMessageConfirm }}</div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary" onclick="">Submit</button>
                        </div>
                    </form>
                </div>

                <!-- Verify your code -->
                <div *ngIf="mobileVerifyForm">
                    <div class="subheading">Verify your account</div>
                    <form [formGroup]="accountVerifyForm" (ngSubmit)="onSubmitMobileVerificationDetails()"
                        class="account-verify-form">
                        <div class="form-group description">
                            <div>Success - your email is verified.</div>
                        </div>
                        <div class="form-group description">
                            <div>We have sent a code to your mobile</div>
                        </div>
                        <input class="d-flex" disabled type="text" [placeholder]="mobileNumber.value">
                        <br />
                        <div class="row">
                            <div class="col-12">
                                <input type="text" id="mobileCode" formControlName="mobileCode" placeholder="Code">
                                <br />
                                <span class="text-danger text-error-message"
                                    *ngIf="mobileCode.touched && mobileCode.hasError('required')">Code is required.
                                </span>
                            </div>
                        </div>
                        <div class="d-flex description">Not received?</div>
                        <button mat-raised-button class="green-button" (click)="onResendMobileConfirmationCode()">Send
                            another
                            code</button>
                        <div class="row" *ngIf="errorMessageConfirmForMobile">
                            <div class="text-danger">{{ errorMessageConfirmForMobile }}</div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</body>

</html>