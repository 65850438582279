export enum UserRole {
  SystemAdmin = 1,

  SuperAdmin =2,

  GroupAdmin =3,

  GroupMember =4,

  GroupCommunicator =5,

  BCUUser = 6,

  PoliceTeamMember = 7,

  Customer = 8,

  User = 9
}
