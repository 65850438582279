import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CognitoService } from '../cognito.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CustomerService } from 'src/app/customer/customer.service';
import { AppStartService } from 'src/app/core/services/app-start.service';

@Component({
  selector: 'app-user-sign-up',
  templateUrl: './user-sign-up.component.html',
  styleUrls: ['./user-sign-up.component.css']
})
export class UserSignUpComponent implements OnInit {
  customerSignUpForm: FormGroup;
  customerVerifyForm: FormGroup;
  accountVerifyForm: FormGroup;

  showUserSignUpForm: boolean = true;
  sawVerifyForm: boolean = false;
  mobileVerifyForm: boolean = false;
  isVerify: boolean = false;

  errorMessage: string = '';
  errorMessageConfirm: string = '';
  errorMessageConfirmForMobile: string = '';
  cognitoUser: any;
  cognitoUserId: string = '';

  constructor(
    private fb: FormBuilder,
    private _cognitoService: CognitoService,
    private _titleService: Title,
    private _activatedRoute: ActivatedRoute,
    public _customerService: CustomerService,
    private _appStartService: AppStartService
  ) { }

  ngOnInit() {
    this.InitForm();
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);
  }

  InitForm() {
    this.customerSignUpForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      organisation: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      mobileNumber: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(11), Validators.pattern(/^(0)?(7\d{9}|1\d{9}|2\d{8}|3\d{7}|5\d{6}|7\d{6}|8\d{5}|9\d{4})/)]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      tickedTerms: [false, Validators.requiredTrue]
    });

    this.customerVerifyForm = this.fb.group({
      code: [null, Validators.required]
    });

    this.accountVerifyForm = this.fb.group({
      mobileCode: [null, Validators.required]
    });
  }

  get firstName() {
    return this.customerSignUpForm.get("firstName") as FormControl;
  }

  get lastName() {
    return this.customerSignUpForm.get("lastName") as FormControl;
  }

  get organisation() {
    return this.customerSignUpForm.get("organisation") as FormControl;
  }

  get email() {
    return this.customerSignUpForm.get("email") as FormControl;
  }

  get mobileNumber() {
    return this.customerSignUpForm.get("mobileNumber") as FormControl;
  }

  get password() {
    return this.customerSignUpForm.get("password") as FormControl;
  }

  get tickedTerms() {
    return this.customerSignUpForm.get("tickedTerms") as FormControl;
  }

  get code() {
    return this.customerVerifyForm.get("code") as FormControl;
  }

  get mobileCode() {
    return this.accountVerifyForm.get("mobileCode") as FormControl;
  }

  formatPhoneNumber(phoneNumber: string): string {
    if (phoneNumber.startsWith('0')) {
      phoneNumber = phoneNumber.substring(1);
    }
    return '+44' + phoneNumber;
  }

  //#region Submit user details
  onSubmitUserDetails() {
    if (this.customerSignUpForm.invalid) {
      this.customerSignUpForm.markAllAsTouched();
      return;
    }

    const { firstName, lastName, organisation, mobileNumber, email, password } = this.customerSignUpForm.value

    this._cognitoService.UserSignUp(
      email, password, {
      email: email,
      first_name: firstName,
      last_name: lastName,
      organisation: organisation,
      mobile_number: this.formatPhoneNumber(mobileNumber)
    })
      .then(data => {
        this.sawVerifyForm = true;
        this.showUserSignUpForm = false;
        this.mobileVerifyForm = false;
        this.errorMessage = '';
        this.cognitoUser = data.user
        this.isVerify = true
        this.cognitoUserId = data.userSub
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error(error);
      });
  }

  //#region Submit email verification code
  onSubmitEmailVerificationDetails() {
    if (this.customerVerifyForm.invalid) {
      this.customerVerifyForm.markAllAsTouched();
      return;
    }

    const { email } = this.customerSignUpForm.value
    const { code } = this.customerVerifyForm.value;
    this._cognitoService.ConfirmSignUp(email, code)
      .then(data => {
        this.onSendMobileVerificationCode()
        this.errorMessageConfirm = '';
      })
      .catch(error => {
        this.errorMessageConfirm = error.message;
      });
  }

  //#region Resend email verification code
  async onResendConfirmationCode() {
    try {
      const { email } = this.customerSignUpForm.value;
      await this._cognitoService.ResendSignUp(email);
    } catch (error) {
      console.error(error);
    }
  }

  //#region Send mobile verification code
  onSendMobileVerificationCode() {
    const { email, password } = this.customerSignUpForm.value
    this._cognitoService.SignInForMobileVerification(email, password)
      .then(async (response) => {
        if (response.signInUserSession.accessToken.jwtToken) {
          this._cognitoService.VerifyCurrentUserAttribute()
          this.sawVerifyForm = false;
          this.showUserSignUpForm = false;
          this.mobileVerifyForm = true;
        }
      })
      .catch(error => {
        this.errorMessageConfirm = error.message;
      });
  }

  //#region Submit mobile verification code
  onSubmitMobileVerificationDetails() {
    if (this.accountVerifyForm.invalid) {
      this.accountVerifyForm.markAllAsTouched();
      return;
    }

    const { mobileCode } = this.accountVerifyForm.value;
    this._cognitoService.VerifyCurrentUserAttributeSubmit(mobileCode)
      .then(data => {
        this.errorMessageConfirmForMobile = '';
        this._appStartService.NavigateToDashboard();
      })
      .catch(error => {
        this.errorMessageConfirmForMobile = error.message;
      });
  }

  //#region Resend mobile verification code
  async onResendMobileConfirmationCode() {
    try {
      await this._cognitoService.VerifyCurrentUserAttribute();
    } catch (error) {
      console.error(error);
    }
  }
}
