import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GooglemapService } from 'src/app/core/services/googlemap.service';
import { GetPolicyEvacuationDTO } from 'src/app/Models/Customer';
import { CustomerService } from "src/app/customer/customer.service";
import { Marker, MouseEvent } from '@agm/core';

@Component({
  selector: 'app-draw-lines-add-form',
  templateUrl: './draw-lines-add-form.component.html',
  styleUrls: ['./draw-lines-add-form.component.css']
})
export class DrawLinesAddFormComponent implements OnInit {
  lng: number;
  lat: number;
  isPin: boolean;
  zoom: number = 18;
  title: string;
  strokeColor = "#80cff4"
  isEvacuationMapRequired: boolean = false;
  isEvacuationMapLengthLimit: boolean = false;
  GetPolicyEvacuationInfo = new GetPolicyEvacuationDTO();

  constructor(
    private _dialogRef: MatDialogRef<DrawLinesAddFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private _googleMapService: GooglemapService,
    private _customerService: CustomerService,
  ) { }

  ngOnInit(): void {
    this.isPin = this._initialInfo.dialogInfo.isPin
    this.lat = this._initialInfo.dialogInfo.lat
    this.lng = this._initialInfo.dialogInfo.lng
    this.title = this._initialInfo.dialogInfo.title
  }

  commanEvacuationFormSubmission() {
    const newData = {
      localityPosition: [...this.GetPolicyEvacuationInfo?.localityPosition],
    };

    if (this.GetPolicyEvacuationInfo?.localityPosition?.length > 1) {
      this.isEvacuationMapLengthLimit = true
      return false;
    }
    this._dialogRef.close(newData);

    return true;
  }

  onSubmitPolicyEvacuation() {
    if (!this.GetPolicyEvacuationInfo?.localityPosition?.length) {
      this.isEvacuationMapRequired = !this.GetPolicyEvacuationInfo?.localityPosition?.length;
      return false;
    }

    this.isEvacuationMapRequired = false;
    this.isEvacuationMapLengthLimit = false;
    if (!this.commanEvacuationFormSubmission()) {
      return;
    }
  }

  OnMapReadyForEvacuation = (map: any) => {
    this._googleMapService.OverlayPolylinehandler(map, null, (path) => {
      if (this.GetPolicyEvacuationInfo.localityPosition == null) {
        this.GetPolicyEvacuationInfo.localityPosition = [];
      }
      this.GetPolicyEvacuationInfo.localityPosition.push(this._customerService.CreatePointArrayForPolygon(path));
    });
  };

  markerDragEnd(m: Marker, $event: MouseEvent) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.updateFurnitureInfo(this.lat, this.lng);
  }

  updateFurnitureInfo(lat: number, lng: number) {
    if (this.GetPolicyEvacuationInfo.localityPosition == null) {
      this.GetPolicyEvacuationInfo.localityPosition = [];
    }
    this.GetPolicyEvacuationInfo.localityPosition.push([{ lat, lng }]);
  }

  RemoveEvacuationPolygon() {
    this.GetPolicyEvacuationInfo.localityMapReinitial = true;
    this.GetPolicyEvacuationInfo.localityPosition = [];
    setTimeout(() => {
      this.GetPolicyEvacuationInfo.localityMapReinitial = false;
    }, 10);
  }
}


