import { Component } from "@angular/core";
import { AppStartService } from './core/services/app-start.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {

  constructor(
    public _appStartService: AppStartService
  ) { 
    (async () => this._appStartService.StartApp())();
  }
}
