import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiErrorService } from "src/app/core/services/api-error.service";
import { AssetsListService } from "src/app/customer/components/assets-list/assets-list.service";
import { CustomerService } from "src/app/customer/customer.service";
import { AssetsDetails, ChecksDetails, CommonType } from "src/app/Models/Customer";

@Component({
  selector: 'app-bulk-check-form',
  templateUrl: './bulk-check-form.component.html',
  styleUrls: ['./bulk-check-form.component.css']
})
export class BulkCheckFormComponent implements OnInit {
  bulkCheckForm: FormGroup;
  venueId: string;
  communicationMethodId: string;
  allData: AssetsDetails
  filterData: any
  listError: boolean = false;
  isBulkCheckSend: boolean = false;

  Days: CommonType[] = [];
  Months: CommonType[] = [];
  Years: CommonType[] = [];

  constructor(
    private _dialogRef: MatDialogRef<BulkCheckFormComponent>,
    private _customerService: CustomerService,
    private _apiErrorService: ApiErrorService,
    private _assetsListService: AssetsListService,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.allData = this._initialInfo.dialogInfo.allInfo
    this.filterData = JSON.parse(JSON.stringify(this.allData));
    this.venueId = this._initialInfo.dialogInfo.venueId
    this.InitForm();
    this.getDays();
    this.getMonths();
    this.getYears();

    let currentDate = new Date();
    let Hours = currentDate.getHours();
    let Time;
    let incidentDateMintes = currentDate.getMinutes().toString();
    if (incidentDateMintes.length == 1) {
      incidentDateMintes = "0" + incidentDateMintes;
    }
    if (Hours < 10) {
      if (currentDate.getHours() > 0) {
        Time =
          "0" +
          Number(Number(currentDate.getHours())) +
          ":" +
          incidentDateMintes;
      } else {
        Time =
          "0" + Number(currentDate.getHours()) + ":" + incidentDateMintes;
      }
    } else {
      Time =
        Number(Number(currentDate.getHours())) + ":" + incidentDateMintes;
    }
    this.bulkCheckForm.get('checkTime')?.setValue(Time);
    this.bulkCheckForm.get('checkDay')?.setValue(currentDate.getDate().toString());
    this.bulkCheckForm.get('checkMonth')?.setValue(Number(
      currentDate.getMonth() + 1
    ).toString());
    this.bulkCheckForm.get('checkYear')?.setValue(currentDate.getFullYear().toString());
  }

  InitForm() {
    this.bulkCheckForm = this.fb.group({
      checkDay: ['', Validators.required],
      checkMonth: ['', Validators.required],
      checkYear: ['', Validators.required],
      checkTime: ['', Validators.required],
    });
  }

  getDays() {
    this.Days = this._customerService.getDays();
  }

  getMonths() {
    this.Months = this._customerService.getMonths();
  }

  getYears() {
    this.Years = this._customerService.getYears();
  }

  get checkDay() {
    return this.bulkCheckForm.get("checkDay") as FormControl;
  }

  get checkMonth() {
    return this.bulkCheckForm.get("checkMonth") as FormControl;
  }

  get checkYear() {
    return this.bulkCheckForm.get("checkYear") as FormControl;
  }

  get checkTime() {
    return this.bulkCheckForm.get("checkTime") as FormControl;
  }

  resetForm(form: FormGroup, defaultValues?: any) {
    if (defaultValues) {
      form.reset(defaultValues);
    } else {
      form.reset();
    }
  }

  removeTypeItem(type: string) {
    const index = this.filterData.indexOf(type);

    if (index !== -1) {
      this.filterData.splice(index, 1);
    }
  }

  onSubmitBulkCheck() {
    if (this.bulkCheckForm.invalid) {
      this.bulkCheckForm.markAllAsTouched();
      return false;
    }

    if (this.filterData.length === 0) {
      this.listError = true
      return false
    }

    this.isBulkCheckSend = true;

    const { checkYear, checkMonth, checkDay, checkTime } = this.bulkCheckForm.value
    const dateTime = this._customerService.GetDateAndTime(checkYear, checkMonth, checkDay, checkTime);
    const assetIds = this.filterData.map(action => action.id);

    let payload = {
      dateTime: dateTime,
      assetIds: assetIds,
    } as ChecksDetails

    this._assetsListService.AddBulkCheckDetails(payload, this.venueId).subscribe(
      (response) => {
        if (response) {
          this.resetForm(this.bulkCheckForm);
          this.isBulkCheckSend = false;
          this._dialogRef.close(payload);
        }
      },
      (error) => {
        this._apiErrorService.ShowError(error);
        this.isBulkCheckSend = false;
      }
    );
  }
}


