import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-help-text-info',
  templateUrl: './help-text-info.component.html',
  styleUrls: ['./help-text-info.component.css']
})
export class HelpTextInfoComponent implements OnInit {
  data:any = [];

  constructor(
    private _dialogRef: MatDialogRef<HelpTextInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
  ) { }

  ngOnInit(): void {
    this.data = this._initialInfo.dialogInfo.allInfo
  }
}
