import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { GooglemapService } from 'src/app/core/services/googlemap.service';
import { AssetsDetails, GetAssetsPerimeterDTO } from 'src/app/Models/Customer';
import { AssetsService } from 'src/app/customer/components/assets/assets.service';

@Component({
  selector: 'app-perimeter-edit-form',
  templateUrl: './perimeter-edit-form.component.html',
  styleUrls: ['./perimeter-edit-form.component.css']
})
export class PerimeterEditFormComponent implements OnInit {
  venueId: string;
  assetId: string;
  lng: number;
  lat: number;
  zoom: number = 18;
  strokeColor = "#80cff4"
  isPerimeterMapRequired: boolean = false;
  isPerimeterSend: boolean = false;
  perimeterOptions = [];
  primeterTabDetails = [];
  mainPerimeter = []
  assetsPerimeterForm: FormGroup;
  GetAssetsPerimeterInfo = new GetAssetsPerimeterDTO();

  constructor(
    private _dialogRef: MatDialogRef<PerimeterEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private _apiErrorService: ApiErrorService,
    private _googleMapService: GooglemapService,
    private _assetsService: AssetsService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.InitForm();
    this.primeterTabDetails = this._initialInfo.dialogInfo.allInfo.area
    this.lat = this._initialInfo.dialogInfo.lat
    this.lng = this._initialInfo.dialogInfo.lng
    this.mainPerimeter = this._initialInfo.dialogInfo.mainPerimeter
    this.assetsPerimeterForm.get('perimeterType')?.setValue(this._initialInfo.dialogInfo.allInfo.type);
    this.assetsPerimeterForm.get('perimeterDescription')?.setValue(this._initialInfo.dialogInfo.allInfo.description);
    this.assetsPerimeterForm.get('frequency')?.setValue(this._initialInfo.dialogInfo.allInfo.assetCheckFrequency);
    this.perimeterOptions = this._initialInfo.dialogInfo.perimeterOptions
    this.venueId =  this._initialInfo.dialogInfo.venueId
    this.assetId =  this._initialInfo.dialogInfo.allInfo.id
  }

  InitForm() {
    this.assetsPerimeterForm = this.fb.group({
      perimeterType: [null, Validators.required],
      perimeterDescription: [null, Validators.required],
      frequency: [null, Validators.required],
    });
  }

  get perimeterType() {
    return this.assetsPerimeterForm.get("perimeterType") as FormControl;
  }

  get perimeterDescription() {
    return this.assetsPerimeterForm.get("perimeterDescription") as FormControl;
  }

  get frequency() {
    return this.assetsPerimeterForm.get("frequency") as FormControl;
  }

  resetForm(form: FormGroup, defaultValues?: any) {
    if (defaultValues) {
      form.reset(defaultValues);
    } else {
      form.reset();
    }
  }

  commanPerimeterFormSubmission() {
    this.isPerimeterSend = true;
      const newData = {
        localityPosition: [...this.GetAssetsPerimeterInfo?.localityPosition, ...this.primeterTabDetails],
        ...this.assetsPerimeterForm.value
      };

      const payload = {
        id: this.venueId,
        category: 1,
        type: parseInt(newData.perimeterType),
        description: newData.perimeterDescription,
        area: newData.localityPosition,
        numberFloors: 0,
        hasPublicAccess: false,
        assetCheckFrequency: parseInt(newData.frequency),
      } as AssetsDetails
      
      this._assetsService.UpdateAssetsTabsDetails(payload, this.venueId, this.assetId).subscribe(
        (response) => {
          if (response) {
            this.resetForm(this.assetsPerimeterForm);
            this.isPerimeterSend = false;
            this._dialogRef.close(this.assetId);
          }
        },
        (error) => {
          this._apiErrorService.ShowError(error);
          this.isPerimeterSend = false;
        }
      );

    return true;
  }

  onSubmitAssetsPerimeter() {
    if (this.assetsPerimeterForm.invalid || (this.primeterTabDetails.length === 0 && !this.GetAssetsPerimeterInfo?.localityPosition?.length)) {
      this.assetsPerimeterForm.markAllAsTouched();
      this.isPerimeterMapRequired = !this.GetAssetsPerimeterInfo?.localityPosition?.length;
      return false;
    }

    this.isPerimeterMapRequired = false;
    if (!this.commanPerimeterFormSubmission()) {
      return;
    }
  }

  OnMapReadyForPerimeter = (map: any) => {
    this._googleMapService.OverlayCompletehandler(map, null, (path) => {
      if (this.GetAssetsPerimeterInfo.localityPosition == null) {
        this.GetAssetsPerimeterInfo.localityPosition = [];
      }
      this.GetAssetsPerimeterInfo.localityPosition.push(this._assetsService.CreatePointArrayForPolygon(path));
    });
  };

  RemovePerimeterPolygon() {
    this.primeterTabDetails = []
    this.GetAssetsPerimeterInfo.localityMapReinitial = true;
    this.GetAssetsPerimeterInfo.localityPosition = [];
    setTimeout(() => {
      this.GetAssetsPerimeterInfo.localityMapReinitial = false;
    }, 10);
  }
}

