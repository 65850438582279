<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="custom-container">
                <div class="form-style w-400 ms-auto me-auto">
                    <form [formGroup]="bulkCheckForm" (ngSubmit)="onSubmitActionCheck()" class="individual-check-form">
                        <div class="fieldset">
                            <div class="mb-3">
                                <div>Action</div>
                                <!-- <textarea class="form-control textarea-box" rows="3" disabled>{{allData.action}}</textarea> -->
                                <textarea formControlName="comment" class="form-control textarea-box" rows="3"
                                    placeholder="e.g. Fence is broken - needs fixing." required></textarea>
                                <span class="text-danger text-error-message"
                                    *ngIf="comment.touched && comment.hasError('required')">
                                    Comment is required
                                </span>
                            </div>
                            <div>
                                <div class="hs-input">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="select-style">
                                                <select class="form-control" formControlName="checkDay" required>
                                                    <option [value]="day.id" *ngFor="let day of Days">{{day.name}}
                                                    </option>
                                                </select>
                                                <span class="text-danger text-error-message"
                                                    *ngIf="checkDay.touched && checkDay.hasError('required')">
                                                    Day is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="select-style">
                                                <select class="form-control" formControlName="checkMonth" required>
                                                    <option [value]="month.id" *ngFor="let month of
                                              Months">{{month.name}}</option>
                                                </select>
                                                <span class="text-danger text-error-message"
                                                    *ngIf="checkMonth.touched && checkMonth.hasError('required')">
                                                    Month is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="select-style">
                                                <select class="form-control" formControlName="checkYear" required>
                                                    <option [value]="year.id" *ngFor="let year of Years">{{year.name}}
                                                    </option>
                                                </select>
                                                <span class="text-danger text-error-message"
                                                    *ngIf="checkYear.touched && checkYear.hasError('required')">
                                                    Year is required
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="hs-input time-input">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <input type="time" class="form-control" formControlName="checkTime"
                                                required>
                                            <span class="text-danger text-error-message"
                                                *ngIf="checkTime.touched && checkTime.hasError('required')">
                                                Time is required
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="next-btn">
                            <div class="d-flex flex-column justify-content-between">
                                <div class="hs-submit text-start">
                                    <button type="submit" class="primary-btn">
                                        <div *ngIf="!isActionResolvedCheckSend">
                                            Done
                                        </div>
                                        <div *ngIf="isActionResolvedCheckSend"
                                            class="d-flex align-items-center justify-content-center">
                                            <mat-spinner class="custom-button-spinner" diameter="20"></mat-spinner>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>