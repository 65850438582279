import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RoomAssetsColor, RoomAssetsName } from 'src/app/Enum/RoomAssets.enum';
import { BuildingLayoutFormLocationEntity, GetAssetsBuildingLayoutDTO, Markers } from 'src/app/Models/Customer';
import { RoomAssetsAddEditFormComponent } from '../room-assets-add-edit-form/room-assets-add-edit-form.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from 'src/app/customer/customer.service';


@Component({
  selector: 'app-building-layout-add-edit-form',
  templateUrl: './building-layout-add-edit-form.component.html',
  styleUrls: ['./building-layout-add-edit-form.component.css']
})
export class BuildingLayoutAddEditFormComponent implements OnInit {
  BLUE_MARKER_URL = "../assets/images/blue-big-marker.png";
  GRAB_BAGS_MARKER_URL = "../assets/images/grab-bags.png";
  FIRST_AID_KITS_MARKER_URL = "../assets/images/first-aid-kits.png";
  lng: number;
  lat: number;
  zoom: number = 18;
  markers: Markers[] = [];
  mainPerimeter = []
  buildingOptions = [];
  strokeColor = "#80cff4"
  venueId: string;
  isBuildingLayoutMapRequired: boolean = false;
  assetsBuildingLayoutForm: FormGroup;
  GetAssetsBuildingLayoutInfo = new GetAssetsBuildingLayoutDTO();

  DRAW_MAP_MODAL_WIDTH_CSS_VALUE = "auto";
  DRAW_MAP_MODAL_HEIGHT_CSS_VALUE = "90%";
  RendezvousPointLocations: BuildingLayoutFormLocationEntity[] = [];
  RendezvousPointLocationsToShow: BuildingLayoutFormLocationEntity[] = [];
  MainLocationPolygonToShow: { id: string, path: any; color: string, name: string }[] = [];
  MainLocationMarkerToShow: { id: string, path: any; color: string, name: string, icon: string, label: string, lat: number, lng: number }[] = [];
  GrabBagsPointLocations: BuildingLayoutFormLocationEntity[] = [];
  FirstAidKitsPointLocations: BuildingLayoutFormLocationEntity[] = [];

  PrivateAreaLocations: BuildingLayoutFormLocationEntity[] = [];
  PrivateRoomLocations: BuildingLayoutFormLocationEntity[] = [];
  PublicAreaLocations: BuildingLayoutFormLocationEntity[] = [];
  WindowsLocations: BuildingLayoutFormLocationEntity[] = [];
  ExitsLocations: BuildingLayoutFormLocationEntity[] = [];
  StairsEscalatorsLocations: BuildingLayoutFormLocationEntity[] = [];
  LiftsLocations: BuildingLayoutFormLocationEntity[] = [];
 
  constructor(
    private fb: FormBuilder,
    private _dialogRef: MatDialogRef<BuildingLayoutAddEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private _dialog: MatDialog,
    public _customerService: CustomerService,
  ) { }

  ngOnInit(): void {
    this.InitForm();
    this.lat = this._initialInfo.dialogInfo.lat
    this.lng = this._initialInfo.dialogInfo.lng
  }

  InitForm() {
    this.assetsBuildingLayoutForm = this.fb.group({
      buildingLayoutDescription: [null, Validators.required],
    });
  }

  get buildingLayoutDescription() {
    return this.assetsBuildingLayoutForm.get("buildingLayoutDescription") as FormControl;
  }

  GetTypeNameFromId(options, typeId: string) {
    const name = options.filter(item => item.value === typeId).map(item => item.displayName);
    return name
  }

  numberToWord(n: number): string {
    return this._customerService.numberToWord(n);
  }


  CreateDrawMapSendDataObject(drawingType: string, color: string, id: string = null, isEdit: boolean = false, locationToBeUpdate: BuildingLayoutFormLocationEntity = null,) {
    return {
      id: "e27e7372-51e1-4ecd-c5ec-08dc4a878bfa",
      isEdit: isEdit,
      latitude: this.lat,
      longitude: this.lng,
      zoom: this.zoom,
      drawingType: drawingType,
      incidentId: "e27e7372-51e1-4ecd-c5ec-08dc4a878bfa",
      color: color,
      existingAllPolygon: this.MainLocationPolygonToShow,
      existingRVPMarkers: this.MainLocationMarkerToShow,
      LocationToBeUpdate: locationToBeUpdate,
    }
  }

  InitializeBuildingLayoutFormLocationEntityInstanceFromModalResult(instance: BuildingLayoutFormLocationEntity, modalResult: any, icon: string, lat = 0, lng = 0,) {
    instance.name = modalResult.name;
    instance.id = "e27e7372-51e1-4ecd-c5ec-08dc4a878bfa";
    instance.description = modalResult.description;
    instance.lat = lat;
    instance.lng = lng;
    instance.label = "";
    instance.draggable = true;
    instance.visible = true;
    instance.opacity = 1;
    instance.icon = icon;
    instance.pathList = modalResult.locations;
    instance.locationDTO = modalResult.locationDTO;

    return instance;
  }

  openDialog(roomType: RoomAssetsName, roomColor: RoomAssetsColor, id?: string, isUpdate?: boolean, entity?: BuildingLayoutFormLocationEntity) {
    const data = this.CreateDrawMapSendDataObject(roomType, roomColor, id, isUpdate, entity);
    return this._dialog.open(RoomAssetsAddEditFormComponent, {
      height: "80%",
      width: this.DRAW_MAP_MODAL_WIDTH_CSS_VALUE,
      data: data
    });
  }

  handleDialogResult(data: any, roomType: RoomAssetsName, roomColor: RoomAssetsColor, locations: BuildingLayoutFormLocationEntity[], polygons: any[]) {
    if (data) {
      const entity = this.InitializeBuildingLayoutFormLocationEntityInstanceFromModalResult(new BuildingLayoutFormLocationEntity(), data, "");
      const index = locations.findIndex(x => x.id == data.id);
      if (index !== -1) {
        locations[index] = entity;
        polygons[index] = { id: data.id, path: data.locations, color: roomColor, name: data.name };
      } else {
        locations.push(entity);
        polygons.push({ id: "e27e7372-51e1-4ecd-c5ec-08dc4a878bfa", path: data.locations, color: roomColor, name: data.name });
      }
    }
  }

  openMarkerDialog(roomType: RoomAssetsName, roomColor: RoomAssetsColor, id?: string, isUpdate?: boolean, entity?: BuildingLayoutFormLocationEntity) {
    const marker = {
      lat: entity ? entity.lat : this.lat,
      lng: entity ? entity.lng : this.lng,
      label: entity ? entity.label : "",
      draggable: entity ? entity.draggable : true,
      visible: entity ? entity.visible : true,
      opacity: 1,
      icon: roomType === RoomAssetsName.GrabBags ? this.GRAB_BAGS_MARKER_URL : this.FIRST_AID_KITS_MARKER_URL
    };

    const data = this.CreateDrawMapSendDataObject(roomType, roomColor, id, isUpdate, entity);
    return this._dialog.open(RoomAssetsAddEditFormComponent, {
      height: this.DRAW_MAP_MODAL_HEIGHT_CSS_VALUE,
      width: this.DRAW_MAP_MODAL_WIDTH_CSS_VALUE,
      data: { ...data, marker }
    });
  }

  handleMarkerDialogResult(data, roomType, roomColor, locationsArray) {
    if (data) {
      const entity = this.InitializeBuildingLayoutFormLocationEntityInstanceFromModalResult(
        new BuildingLayoutFormLocationEntity(),
        data,
        roomType === RoomAssetsName.GrabBags ? this.GRAB_BAGS_MARKER_URL : this.FIRST_AID_KITS_MARKER_URL
      );
      entity.lat = data.locations[0].lat;
      entity.lng = data.locations[0].lng;

      const index = locationsArray.findIndex(x => x.id === data.id);
      const markerData = {
        id: data.id,
        lat: data.locations[0].lat,
        lng: data.locations[0].lng,
        path: data.locations,
        color: roomColor,
        name: data.name,
        icon: roomType === RoomAssetsName.GrabBags ? this.GRAB_BAGS_MARKER_URL : this.FIRST_AID_KITS_MARKER_URL,
        label: ''
      };

      if (index !== -1) {
        locationsArray[index] = entity;
        this.MainLocationMarkerToShow[index] = markerData;
      } else {
        locationsArray.push(entity);
        this.MainLocationMarkerToShow.push(markerData);
      }
    }
  }

  //#region private area
  AddPrivateAreaClicked() {
    this.openDialog(RoomAssetsName.PrivateArea, RoomAssetsColor.PrivateArea)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.PrivateArea, RoomAssetsColor.PrivateArea, this.PrivateAreaLocations, this.MainLocationPolygonToShow));
  }

  UpdatePrivateAreaClicked(PrivateArea: BuildingLayoutFormLocationEntity) {
    this.openDialog(RoomAssetsName.PrivateArea, RoomAssetsColor.PrivateArea, PrivateArea.id, true, PrivateArea)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.PrivateArea, RoomAssetsColor.PrivateArea, this.PrivateAreaLocations, this.MainLocationPolygonToShow));
  }

  //#region private room
  AddPrivateRoomClicked() {
    this.openDialog(RoomAssetsName.PrivateRoom, RoomAssetsColor.PrivateRoom)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.PrivateRoom, RoomAssetsColor.PrivateRoom, this.PrivateRoomLocations, this.MainLocationPolygonToShow));
  }

  UpdatePrivateRoomClicked(PrivateRoom: BuildingLayoutFormLocationEntity) {
    this.openDialog(RoomAssetsName.PrivateRoom, RoomAssetsColor.PrivateRoom, PrivateRoom.id, true, PrivateRoom)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.PrivateRoom, RoomAssetsColor.PrivateRoom, this.PrivateRoomLocations, this.MainLocationPolygonToShow));
  }

  //#region public area
  AddPublicAreaClicked() {
    this.openDialog(RoomAssetsName.PublicArea, RoomAssetsColor.PublicArea)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.PublicArea, RoomAssetsColor.PublicArea, this.PublicAreaLocations, this.MainLocationPolygonToShow));
  }

  UpdatePublicAreaClicked(PublicArea: BuildingLayoutFormLocationEntity) {
    this.openDialog(RoomAssetsName.PublicArea, RoomAssetsColor.PublicArea, PublicArea.id, true, PublicArea)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.PublicArea, RoomAssetsColor.PublicArea, this.PublicAreaLocations, this.MainLocationPolygonToShow));
  }

  //#region windows
  AddWindowsClicked() {
    this.openDialog(RoomAssetsName.Windows, RoomAssetsColor.Windows)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.Windows, RoomAssetsColor.Windows, this.WindowsLocations, this.MainLocationPolygonToShow));
  }

  UpdateWindowsClicked(Windows: BuildingLayoutFormLocationEntity) {
    this.openDialog(RoomAssetsName.Windows, RoomAssetsColor.Windows, Windows.id, true, Windows)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.Windows, RoomAssetsColor.Windows, this.WindowsLocations, this.MainLocationPolygonToShow));
  }

  //#region exits
  AddExitsClicked() {
    this.openDialog(RoomAssetsName.Exits, RoomAssetsColor.Exits)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.Exits, RoomAssetsColor.Exits, this.ExitsLocations, this.MainLocationPolygonToShow));
  }

  UpdateExitsClicked(Exits: BuildingLayoutFormLocationEntity) {
    this.openDialog(RoomAssetsName.Exits, RoomAssetsColor.Exits, Exits.id, true, Exits)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.Exits, RoomAssetsColor.Exits, this.ExitsLocations, this.MainLocationPolygonToShow));
  }

  //#region stairs escalators
  AddStairsEscalatorsClicked() {
    this.openDialog(RoomAssetsName.StairsEscalators, RoomAssetsColor.StairsEscalators)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.StairsEscalators, RoomAssetsColor.StairsEscalators, this.StairsEscalatorsLocations, this.MainLocationPolygonToShow));
  }

  UpdateStairsEscalatorsClicked(StairsEscalators: BuildingLayoutFormLocationEntity) {
    this.openDialog(RoomAssetsName.StairsEscalators, RoomAssetsColor.StairsEscalators, StairsEscalators.id, true, StairsEscalators)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.StairsEscalators, RoomAssetsColor.StairsEscalators, this.StairsEscalatorsLocations, this.MainLocationPolygonToShow));
  }

  //#region stairs escalators
  AddLiftsClicked() {
    this.openDialog(RoomAssetsName.Lifts, RoomAssetsColor.Lifts)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.Lifts, RoomAssetsColor.Lifts, this.LiftsLocations, this.MainLocationPolygonToShow));
  }

  UpdateLiftsClicked(Lifts: BuildingLayoutFormLocationEntity) {
    this.openDialog(RoomAssetsName.Lifts, RoomAssetsColor.Lifts, Lifts.id, true, Lifts)
      .afterClosed()
      .subscribe(data => this.handleDialogResult(data, RoomAssetsName.Lifts, RoomAssetsColor.Lifts, this.LiftsLocations, this.MainLocationPolygonToShow));
  }

    //#region grab bags
    AddGrabBagsClicked() {
      this.openMarkerDialog(RoomAssetsName.GrabBags, RoomAssetsColor.GrabBags)
        .afterClosed()
        .subscribe(data => this.handleMarkerDialogResult(data, RoomAssetsName.GrabBags, RoomAssetsColor.GrabBags, this.GrabBagsPointLocations));
    }
  
    UpdateGrabBagsClicked(GrabBags: BuildingLayoutFormLocationEntity) {
      this.openMarkerDialog(RoomAssetsName.GrabBags, RoomAssetsColor.GrabBags, GrabBags.id, true, GrabBags)
        .afterClosed()
        .subscribe(data => this.handleMarkerDialogResult(data, RoomAssetsName.GrabBags, RoomAssetsColor.GrabBags, this.GrabBagsPointLocations));
    }
  
    //#region first aid kits
    AddFirstAidKitsClicked() {
      this.openMarkerDialog(RoomAssetsName.FirstAidKits, RoomAssetsColor.FirstAidKits)
        .afterClosed()
        .subscribe(data => this.handleMarkerDialogResult(data, RoomAssetsName.FirstAidKits, RoomAssetsColor.FirstAidKits, this.FirstAidKitsPointLocations));
    }
  
    UpdateFirstAidKitsClicked(FirstAidKits: BuildingLayoutFormLocationEntity) {
      this.openMarkerDialog(RoomAssetsName.FirstAidKits, RoomAssetsColor.FirstAidKits, FirstAidKits.id, true, FirstAidKits)
        .afterClosed()
        .subscribe(data => this.handleMarkerDialogResult(data, RoomAssetsName.FirstAidKits, RoomAssetsColor.FirstAidKits, this.FirstAidKitsPointLocations));
    }

  commanBuildingFormSubmission() {
    if (!this.assetsBuildingLayoutForm.invalid && this.GetAssetsBuildingLayoutInfo?.localityPosition?.length !== 0) {
      const newData = {
        localityPosition: this.GetAssetsBuildingLayoutInfo?.localityPosition,
        ...this.assetsBuildingLayoutForm.value
      };

      // const payload = {
      //   id: this.venueId,
      //   category: 2,
      //   type: parseInt(newData.buildingType),
      //   description: newData.buildingDescription,
      //   area: newData.localityPosition,
      //   numberFloors: parseInt(newData.numberOfFloors),
      //   hasPublicAccess: newData.buildingAccess === "true" ? true : false
      // } as AssetsDetails

      // this._assetsService.AddAssetsDetails(payload, this.venueId).subscribe(
      //   (response) => {
      //     if (response) {
      //       this.getBuildingCategoryDetails("2")
      //       this.resetForm(this.assetsBuildingLayoutForm);
      //     }
      //   },
      //   (error) => {
      //     this._apiErrorService.ShowError(error);
      //   }
      // );
    }

    return true;
  }

  onSubmitAssetsBuilding() {
    if (this.assetsBuildingLayoutForm.invalid || !this.GetAssetsBuildingLayoutInfo?.localityPosition?.length) {
      this.assetsBuildingLayoutForm.markAllAsTouched();
      this.isBuildingLayoutMapRequired = !this.GetAssetsBuildingLayoutInfo?.localityPosition?.length;
      return false;
    }

    this.isBuildingLayoutMapRequired = false;

    if (!this.commanBuildingFormSubmission()) {
      return;
    }
  }

  closeMapClicked() {
    this._dialogRef.close();
  }
}
