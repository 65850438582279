import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/interceptors/api.service';
import { TeamMember } from 'src/app/Models/Team-member/TeamMemberDTO';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {

  constructor(private _apiService: ApiService) { }

  AddTeamMember(venueId: string, teamember : TeamMember) {
    const url = `/Venue/People/${venueId}`;
    return this._apiService.post(url, teamember).pipe(
      map((data) => {
        return data;
      })
    );
  }

  EditTeamMember(venueId: string, teamember : TeamMember) {
    const url = `/Venue/People/${venueId}`;
    return this._apiService.post(url, teamember).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
