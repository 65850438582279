<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="heading-section">Lockdown Info</div>
            <div class="custom-container">
                <div class="form-style w-400 ms-auto me-auto accordianHeight">
                    <form [formGroup]="policyLockdownForm" (ngSubmit)="onSubmitPolicyLockdown()">
                        <div class="fieldset">
                            <div class="main-form">
                                <div class="main-building-title mb-3"><span>{{assetData.typeName}}</span>
                                </div>
                                <div class="tabs-title">Lockdown plan</div>
                                <div class="mb-4">
                                    <div>How will you lock down the building?</div>
                                    <textarea formControlName="lockdownDescription" class="form-control textarea-box"
                                        rows="3"
                                        placeholder="e.g. Use automated alerting systemLorem ipsum dolor sit amet, consectetuer adipiscing"
                                        required></textarea>
                                    <span class="text-danger text-error-message"
                                        *ngIf="lockdownDescription.touched && lockdownDescription.hasError('required')">
                                        Description is required
                                    </span>
                                </div>

                                <div class="mb-3 floor-detail"><span>
                                        {{numberToWord(assetData.numberFloors)}} floor</span></div>
                                <p class="max-w mb-4">Where are the safe places where you could
                                    bring people in to?</p>
                                <div class="d-flex mb-4">
                                    <img src="../../assets/images/lockdown-icon.png" alt="marker-icon" class="h-24">
                                    <div class="invacuation-btn">Window lock</div>
                                    <img src="../../assets/images/edit-icon.png" alt="plus icon" class="showHand h-24"
                                        (click)="onUpdateWindowLockPin()">
                                </div>
                                <div class="d-flex mb-4">
                                    <img src="../../assets/images/invacuation-icon.png" alt="marker-icon" class="h-24">
                                    <div class="invacuation-btn">Door lock </div>
                                    <img src="../../assets/images/edit-icon.png" alt="plus icon" class="showHand h-24"
                                        (click)="onUpdateDoorLockPin()">
                                </div>
                                <div class="d-flex mb-4"><input type="checkbox" class="checkbox" id="unableToLock"
                                        formControlName="unableToLock">
                                    <label for="unableToLock" class="checkbox-label">Unable to lock
                                        doors / windows in this area</label>
                                </div>
                               
                                <div class="next-btn mb-0 mt-5">
                                    <div class="d-flex flex-column justify-content-between">
                                        <div class="hs-submit text-start">
                                            <button type="submit" class="primary-btn">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- Main Map -->
                <div class="modal map-modal-style show">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body d-flex align-items-center">
                                <div class="w-100">
                                    <div class="map-iframe" *ngIf="!GetPolicyLockdownInfo.localityMapReinitial">
                                        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom"
                                            [fullscreenControl]='true' [mapTypeControl]='true'>
                                            <agm-polygon *ngFor="let path of mainPerimeter" [paths]="path"
                                                [strokeColor]='strokeColor' fillOpacity="transparent"
                                                fillColor="transparent">
                                            </agm-polygon>
                                            <agm-marker [iconUrl]="{url: LOCKABLE_DOOR_MARKER_URL}"
                                                [latitude]="windowLockData.length > 0 && windowLockData[0].lat"
                                                [longitude]="windowLockData.length > 0 && windowLockData[0].lng">
                                            </agm-marker>
                                            <agm-marker [iconUrl]="{url: DOOR_LOCK_MARKER_URL}"
                                                [latitude]="doorLockData.length > 0 && doorLockData[0].lat"
                                                [longitude]="doorLockData.length > 0 && doorLockData[0].lng">
                                            </agm-marker>
                                        </agm-map>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>