<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
                <div class="custom-container">
                    <div class="form-style w-400 ms-auto me-auto accordianHeight">
                        <form [formGroup]="assetsBuildingLayoutForm" (ngSubmit)="onSubmitAssetsBuilding()"
                            class="customer-signup-form">
                            <div class="fieldset building-layouts">
                                <div class="heading-section">Building layouts
                                </div>
                                <div class="mb-4">
                                    <div>
                                        <span>Building</span> : {{GetTypeNameFromId(buildingOptions, "")}}
                                    </div>
                                    <div>
                                        <span>Floor</span> : {{numberToWord(0)}} floor
                                    </div>
                                </div>
                                <div class="rooms-areas">
                                    <span>Rooms and areas</span>
                                    <div class="building-custom-maps">
                                        <div class="label" (click)="AddPrivateRoomClicked()">
                                            <div class="map-icon">
                                                <img src="assets/images/private-area.png" alt="private area">
                                            </div>
                                            Private room
                                            <button type="button" class="plus-btn">
                                                <img src="assets/images/plus-black.png" alt="plus-black">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="crime-scene-box d-flex align-items-center justify-content-center"
                                        *ngFor="let PrivateRoomLocationForName of PrivateRoomLocations; index as i">
                                        <div class="right-details" [id]="'panel-corden-spinner' + i"
                                            style="display: flex; justify-content: space-between;">
                                            <div class="scene-t">Private room - {{i + 1}}</div>
                                            <div class="scene-update showHand"
                                                (click)="UpdatePrivateRoomClicked(PrivateRoomLocationForName)">
                                                Update
                                            </div>
                                        </div>
                                    </div>
                                    <div class="building-custom-maps">
                                        <div class="label" (click)="AddPrivateAreaClicked()">
                                            <div class="map-icon">
                                                <img src="assets/images/private-area.png" alt="private area">
                                            </div>
                                            Private area
                                            <button type="button" class="plus-btn">
                                                <img src="assets/images/plus-black.png" alt="plus-black">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="crime-scene-box d-flex align-items-center justify-content-center"
                                        *ngFor="let PrivateAreaLocationForName of PrivateAreaLocations; index as i">
                                        <div class="right-details" [id]="'panel-corden-spinner' + i"
                                            style="display: flex; justify-content: space-between;">
                                            <div class="scene-t">Private area - {{i + 1}}</div>
                                            <div class="scene-update showHand"
                                                (click)="UpdatePrivateAreaClicked(PrivateAreaLocationForName)">
                                                Update
                                            </div>
                                        </div>
                                    </div>
                                    <div class="building-custom-maps">
                                        <div class="label" (click)="AddPublicAreaClicked()">
                                            <div class="map-icon">
                                                <img src="assets/images/public-area.png" alt="public area">
                                            </div>
                                            Public area
                                            <button type="button" class="plus-btn">
                                                <img src="assets/images/plus-black.png" alt="plus-black">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="crime-scene-box d-flex align-items-center justify-content-center"
                                        *ngFor="let PublicAreaLocationForName of PublicAreaLocations; index as i">
                                        <div class="right-details" [id]="'panel-corden-spinner' + i"
                                            style="display: flex; justify-content: space-between;">
                                            <div class="scene-t">Public area - {{i + 1}}</div>
                                            <div class="scene-update showHand"
                                                (click)="UpdatePublicAreaClicked(PublicAreaLocationForName)">Update
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="rooms-assets">
                                    <span>Rooms assets</span>
                                    <div class="building-custom-maps">
                                        <div class="label" (click)="AddWindowsClicked()">
                                            <div class="map-icon">
                                                <img src="assets/images/windows.png" alt="Windows">
                                            </div>
                                            Windows
                                            <button type="button" class="plus-btn">
                                                <img src="assets/images/plus-black.png" alt="plus-black">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="crime-scene-box d-flex align-items-center justify-content-center"
                                        *ngFor="let WindowsLocationForName of WindowsLocations; index as i">
                                        <div class="right-details" [id]="'panel-corden-spinner' + i"
                                            style="display: flex; justify-content: space-between;">
                                            <div class="scene-t">Window - {{i + 1}}</div>
                                            <div class="scene-update showHand"
                                                (click)="UpdateWindowsClicked(WindowsLocationForName)">Update</div>
                                        </div>
                                    </div>
                                    <div class="building-custom-maps">
                                        <div class="label" (click)="AddExitsClicked()">
                                            <div class="map-icon">
                                                <img src="assets/images/exits.png" alt="Exits">
                                            </div>
                                            Exits
                                            <button type="button" class="plus-btn">
                                                <img src="assets/images/plus-black.png" alt="plus-black">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="crime-scene-box d-flex align-items-center justify-content-center"
                                        *ngFor="let ExitsLocationForName of ExitsLocations; index as i">
                                        <div class="right-details" [id]="'panel-corden-spinner' + i"
                                            style="display: flex; justify-content: space-between;">
                                            <div class="scene-t">Exit - {{i + 1}}</div>
                                            <div class="scene-update showHand"
                                                (click)="UpdateExitsClicked(ExitsLocationForName)">Update</div>
                                        </div>
                                    </div>
                                    <div class="building-custom-maps">
                                        <div class="label" (click)="AddStairsEscalatorsClicked()">
                                            <div class="map-icon">
                                                <img src="assets/images/stairs.png" alt="Stairs escalators">
                                            </div>
                                            Stairs escalators
                                            <button type="button" class="plus-btn">
                                                <img src="assets/images/plus-black.png" alt="plus-black">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="crime-scene-box d-flex align-items-center justify-content-center"
                                        *ngFor="let StairsEscalatorsLocationForName of StairsEscalatorsLocations; index as i">
                                        <div class="right-details" [id]="'panel-corden-spinner' + i"
                                            style="display: flex; justify-content: space-between;">
                                            <div class="scene-t">Stairs escalator - {{i + 1}}</div>
                                            <div class="scene-update showHand"
                                                (click)="UpdateStairsEscalatorsClicked(StairsEscalatorsLocationForName)">
                                                Update</div>
                                        </div>
                                    </div>
                                    <div class="building-custom-maps">
                                        <div class="label" (click)="AddLiftsClicked()">
                                            <div class="map-icon">
                                                <img src="assets/images/lifts.png" alt="Lifts">
                                            </div>
                                            Lifts
                                            <button type="button" class="plus-btn">
                                                <img src="assets/images/plus-black.png" alt="plus-black">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="crime-scene-box d-flex align-items-center justify-content-center"
                                        *ngFor="let LiftsLocationForName of LiftsLocations; index as i">
                                        <div class="right-details" [id]="'panel-corden-spinner' + i"
                                            style="display: flex; justify-content: space-between;">
                                            <div class="scene-t">Lift - {{i + 1}}</div>
                                            <div class="scene-update showHand"
                                                (click)="UpdateLiftsClicked(LiftsLocationForName)">Update</div>
                                        </div>
                                    </div>
                                    <div class="building-custom-maps">
                                        <div class="label" (click)="AddFirstAidKitsClicked()">
                                            <div class="map-icon">
                                                <img src="assets/images/first-aid-kits.png" alt="First aid kits">
                                            </div>
                                            First aid kits
                                            <button type="button" class="plus-btn">
                                                <img src="assets/images/plus-black.png" alt="plus-black">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="crime-scene-box d-flex align-items-center justify-content-center"
                                        *ngFor="let FirstAidKitsLocationForName of FirstAidKitsPointLocations; index as i">
                                        <div class="right-details" [id]="'panel-corden-spinner' + i"
                                            style="display: flex; justify-content: space-between;">
                                            <div class="scene-t">First aid kits - {{i + 1}}</div>
                                            <div class="scene-update showHand"
                                                (click)="UpdateFirstAidKitsClicked(FirstAidKitsLocationForName)">Update</div>
                                        </div>
                                    </div>
                                    <div class="building-custom-maps">
                                        <div class="label" (click)="AddGrabBagsClicked()">
                                            <div class="map-icon">
                                                <img src="assets/images/grab-bags.png" alt="Grab bags">
                                            </div>
                                            Grab bags
                                            <button type="button" class="plus-btn">
                                                <img src="assets/images/plus-black.png" alt="plus-black">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="crime-scene-box d-flex align-items-center justify-content-center"
                                        *ngFor="let GrabBagsLocationForName of GrabBagsPointLocations; index as i">
                                        <div class="right-details" [id]="'panel-corden-spinner' + i"
                                            style="display: flex; justify-content: space-between;">
                                            <div class="scene-t">Grab bag - {{i + 1}}</div>
                                            <div class="scene-update showHand"
                                                (click)="UpdateGrabBagsClicked(GrabBagsLocationForName)">Update</div>
                                        </div>
                                    </div>
                                </div>
    
                                <!-- <div class="mb-4">
                                    <span class="num-circle">2</span>
                                    <div>Now, draw on the map where the building or outbuilding is located ▶</div>
                                    <span class="text-danger text-error-message" *ngIf="isBuildingMapRequired">
                                        Map is required
                                    </span>
                                </div> -->
                                <div class="mb-5">
                                    <div>Add a brief description.</div>
                                    <textarea formControlName="buildingLayoutDescription" class="form-control textarea-box"
                                        rows="4" placeholder="e.g. main bar area." required></textarea>
                                    <span class="text-danger text-error-message"
                                        *ngIf="buildingLayoutDescription.touched && buildingLayoutDescription.hasError('required')">
                                        Description is required
                                    </span>
                                </div>
                            </div>
    
                            <div class="next-btn">
                                <div class="d-flex flex-column justify-content-between">
                                    <div class="hs-submit text-start">
                                        <button type="submit" class="primary-btn">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
    
                       
                    </div>
                     <!-- Main map -->
                     <div class="modal map-modal-style show">
                        <div class="modal-dialog">
                            <div class="modal-content">

                                <div class="modal-body d-flex align-items-center">
                                    <div class="w-full">
                                        <div class="map-iframe">
                                            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom"
                                                [fullscreenControl]='true' [mapTypeControl]='true'>
                                                <agm-polygon *ngFor="let path of mainPerimeter" [paths]="path"
                                                    [strokeColor]='strokeColor' fillOpacity="transparent"
                                                    fillColor="transparent">
                                                </agm-polygon>
                                                <agm-marker *ngFor="let m of MainLocationMarkerToShow; let i=index"
                                                    [iconUrl]="{url: m?.icon}" [latitude]="m?.lat" [longitude]="m?.lng"
                                                    [label]="m?.label">
                                                </agm-marker>
                                                <agm-polygon *ngFor="let polygon of MainLocationPolygonToShow"
                                                    [paths]="polygon?.path" [strokeColor]="strokeColor"
                                                    [fillColor]="polygon?.color" [fillOpacity]="polygon?.color" [clickable]="false">
                                                    <agm-marker [latitude]="lat" [longitude]="lng" [label]="'M'"></agm-marker>
                                                </agm-polygon>
                                            </agm-map>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>