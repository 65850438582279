import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StringHelperService } from './string-helper.service';
import { IIRAccordianNames } from 'src/app/bcu/components/iir/iir.service';

@Injectable({
  providedIn: 'root'
})
export class UrlHelperService {

  constructor(
    private _stringHelperService: StringHelperService
  ) { }

  //#region BCU urls

  BcuUserDashboardUrl(): string {
    return '/BCU/Dashboard';
  }

  BcuUserContactUrl(): string {
    return '/BCU/contacts';
  }

  BcuPartnerMembers(): string {
    return '/BCU/partner-members';
  }

  BcuIncidentDetail(id: string): string {
    return `/BCU/incident-detail/${id}`;
  }

  IIRUrl(id: string = '0', tab: string = IIRAccordianNames.StartIncidentTab) {
    return `/BCU/initial-incident-form/${id}/${tab}`;
  }

  BCUTeamMemberUrl(): string {
    return '/BCU/team-members';
  }

  BCUIncidentListUrl(): string {
    return '/BCU/incidents/list';
  }

  //#endregion

  //#region Customer urls

  CustomerDashboardUrl(): string {
    return '/Customer/dashboard';
  }

  CustomerGetStartedUrl(): string {
    return '/Customer/get-started';
  }

  CustomerAssetsUrl( tab: string): string {
    return `/Customer/assets/${tab}`;
  }

  CustomerAssetsListUrl(): string {
    return '/Customer/assets-list';
  }

  CustomerPeopleUrl(): string {
    return '/Customer/people';
  }

  CustomerPoliciesUrl( tab: string): string {
    return `/Customer/policies/${tab}`;
  }

  //#endregion

    //#region User urls

    UserDashboardUrl(): string {
      return '/user/dashboard';
    }
  
    //#endregion


  //#region LA urls


  LaUserDashboardUrl(): string {
    return '/home-dashboard';
  }

  La2UserDashboardUrl(): string {
    return '/la-dashboard';
  }

  LaCstTemplateCreate(): string {
    return '/cst-template-create';
  }

  LAIncidentListUrl(): string {
    return '/incidents/list';
  }


  //#endregion

  //#region Common



  //#endregion



  //#region Auth urls

  IsSignupUrl(url: string): boolean {
    if (this._stringHelperService.IsNullOrWhiteSpace(url)) {
      return false;
    }
    return url.startsWith("/sign-up/") || url.startsWith("/account/customer-sign-up") || url.startsWith("/account/user-sign-up");
  }

  IsChangePasswordUrl(url): boolean {
    if (this._stringHelperService.IsNullOrWhiteSpace(url)) {
      return false;
    }
    return url.startsWith("change-password");
  }

  GetChangePasswordUrl() {
    return '/change-password';
  }

  //#endregion



  //#region Help urls

  GetSimHelpURL() {
    return environment.PCR_help_URL;
  }

  //#endregion
}
