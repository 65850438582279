import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { GooglemapService } from 'src/app/core/services/googlemap.service';
import { GetPolicyEvacuationDTO, PoliciesDetails } from 'src/app/Models/Customer';
import { PoliciesService } from "src/app/customer/components/policies/policies.service";
import { CustomerService } from "src/app/customer/customer.service";

@Component({
  selector: 'app-evacuation-add-edit-form',
  templateUrl: './evacuation-add-edit-form.component.html',
  styleUrls: ['./evacuation-add-edit-form.component.css']
})
export class EvacuationAddEditFormComponent implements OnInit {
  venueId: string;
  assetId: string;
  evacuationRouteId: string;
  isEdit: boolean = false;
  lng: number;
  lat: number;
  zoom: number = 18;
  strokeColor = "#80cff4"
  isEvacuationMapRequired: boolean = false;
  isEvacuationMapLengthLimit: boolean = false;
  isEvacuationSend: boolean = false
  evacuationTabDetails = [];
  mainPerimeter = []
  assetData:any = []
  policyEvacuationForm: FormGroup;
  GetPolicyEvacuationInfo = new GetPolicyEvacuationDTO();

  constructor(
    private _dialogRef: MatDialogRef<EvacuationAddEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private _apiErrorService: ApiErrorService,
    private _googleMapService: GooglemapService,
    private _policiesService: PoliciesService,
    private _customerService: CustomerService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.venueId = this._initialInfo.dialogInfo.venueId
    this.isEdit = this._initialInfo.dialogInfo.isEdit
    this.assetId = this._initialInfo.dialogInfo.assetData.id
    this.assetData = this._initialInfo.dialogInfo.assetData
    this.InitForm();

    this.lat = this._initialInfo.dialogInfo.lat
    this.lng = this._initialInfo.dialogInfo.lng
    this.mainPerimeter = this._initialInfo.dialogInfo.mainPerimeter

    if (this.isEdit) {
      this.policyEvacuationForm.get('evacuationDescription')?.setValue(this._initialInfo.dialogInfo.allInfo.description);
      this.evacuationTabDetails = this._initialInfo.dialogInfo.allInfo.routePoints;
      this.evacuationRouteId = this._initialInfo.dialogInfo.allInfo.id;
    }
  }

  InitForm() {
    this.policyEvacuationForm = this.fb.group({
      evacuationDescription: [null, Validators.required],
    });
  }

  get evacuationDescription() {
    return this.policyEvacuationForm.get("evacuationDescription") as FormControl;
  }

  numberToWord(n: number): string {
    return this._customerService.numberToWord(n);
  }

  resetForm(form: FormGroup, defaultValues?: any) {
    if (defaultValues) {
      form.reset(defaultValues);
    } else {
      form.reset();
    }
  }

  commanEvacuationFormSubmission() {
    this.isEvacuationSend = true;
    const newData = {
      localityPosition: [...this.GetPolicyEvacuationInfo?.localityPosition],
      ...this.policyEvacuationForm.value
    };
    
    if(this.GetPolicyEvacuationInfo?.localityPosition?.length > 1){
      this.isEvacuationMapRequired = true
      return false;
    }


    const payload = {
      id: this.venueId,
      description: newData.evacuationDescription,
      routePoints: this.isEdit ? this.evacuationTabDetails.length > 0 ? this.evacuationTabDetails : newData.localityPosition[0] : newData.localityPosition[0],
    } as PoliciesDetails

    if (this.isEdit) {
      this._policiesService.UpdateEvacuationDetails(payload, this.venueId, this.assetId, this.evacuationRouteId).subscribe(
        (response) => {
          if (response) {
            this.resetForm(this.policyEvacuationForm);
            this.isEvacuationSend = false;
            this._dialogRef.close(this.assetId);
          }
        },
        (error) => {
          this._apiErrorService.ShowError(error);
          this.isEvacuationSend = false;
        }
      );
    }
    return true;
  }

  onSubmitPolicyEvacuation() {
    if (this.policyEvacuationForm.invalid || (this.evacuationTabDetails.length === 0 && !this.GetPolicyEvacuationInfo?.localityPosition?.length)) {
      this.policyEvacuationForm.markAllAsTouched();
      this.isEvacuationMapRequired = !this.GetPolicyEvacuationInfo?.localityPosition?.length;
      return false;
    }

    if(this.GetPolicyEvacuationInfo?.localityPosition?.length > 1){
      this.isEvacuationMapLengthLimit = true
      return false;
    }

    this.isEvacuationMapRequired = false;
    this.isEvacuationMapLengthLimit = false;
    if (!this.commanEvacuationFormSubmission()) {
      return;
    }
  }

  OnMapReadyForEvacuation = (map: any) => {
    this._googleMapService.OverlayPolylinehandler(map, null, (path) => {
      if (this.GetPolicyEvacuationInfo.localityPosition == null) {
        this.GetPolicyEvacuationInfo.localityPosition = [];
      }
      this.GetPolicyEvacuationInfo.localityPosition.push(this._customerService.CreatePointArrayForPolygon(path));
    });
  };

  RemoveEvacuationPolygon() {
    this.evacuationTabDetails = []
    this.GetPolicyEvacuationInfo.localityMapReinitial = true;
    this.GetPolicyEvacuationInfo.localityPosition = [];
    setTimeout(() => {
      this.GetPolicyEvacuationInfo.localityMapReinitial = false;
    }, 10);
  }
}

