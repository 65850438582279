import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/interceptors/api.service';
import { map } from 'rxjs/operators';
import { CommunicationDetails, PoliciesDetails } from 'src/app/Models/Customer';

@Injectable({
  providedIn: 'root'
})
export class PoliciesService {

  constructor( private _apiService: ApiService) { }

  AddCommunicationDetails(details : CommunicationDetails, venueId: string) {
    const url = `/Venue/CommunicationMethod/Add/${venueId}`;
    return this._apiService.post(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }

  UpdateCommunicationDetails(details: CommunicationDetails,venueId: string,communicationMethodId: string) {
    const url = `/Venue/CommunicationMethod/Edit/${venueId}/${communicationMethodId}`;
    return this._apiService.put(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }

  DeleteCommunicationDetails(venueId: string,communicationMethodId: string) {
    const url = `/Venue/CommunicationMethod/Delete/${venueId}/${communicationMethodId}`;
    return this._apiService.put(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  AddEvacuationDetails(details : PoliciesDetails, venueId: string, assetId: string) {
    const url = `/Venue/EvacuationRoute/Add/${venueId}/${assetId}`;
    return this._apiService.post(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }

  UpdateEvacuationDetails(details : PoliciesDetails, venueId: string, assetId: string, evacuationRouteId:string) {
    const url = `/Venue/EvacuationRoute/Edit/${venueId}/${assetId}/${evacuationRouteId}`;
    return this._apiService.put(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }

  DeleteEvacuationDetails(venueId: string, assetId: string, evacuationRouteId:string) {
    const url = `/Venue/EvacuationRoute/Delete/${venueId}/${assetId}/${evacuationRouteId}`;
    return this._apiService.put(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
