import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/interceptors/api.service';
import { CustomerProfile, OrganisationDetails, VenueDetails } from 'src/app/Models/Authentication/User';
import { Markers } from 'src/app/Models/Customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerSignUpService {
  constructor(
    private _apiService: ApiService
  ) { }

  InstanciateMainRedMarker(lat: number, lng: number, isVisible: boolean): Markers{
    const marker = new Markers();
    marker.lat = lat;
    marker.lng = lng;
    marker.label = "";
    marker.draggable = true;
    marker.visible = isVisible;
    marker.opacity = 0.7;
    marker.icon = "../../../assets/images/red-marker.png";
    return marker;
  }

  AddUser(user : CustomerProfile) {
    const url = `/Account/CreateUserAndProfileAndOrganisation`;
    return this._apiService.post(url, user).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetPrices() {
    const url = `/Stripe/Prices`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  AddVenueDetails(details : VenueDetails) {
    const url = `/Venue/Create`;
    return this._apiService.post(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }

  AddOrganisationDetails(details : OrganisationDetails) {
    const url = `/Venue/AddAddress`;
    return this._apiService.post(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }
}

export enum FindYourVenueAccordianNames {
  FindYourVenueTab = "FindYourVenueTab",
  OrganisationDetailsTab = "OrganisationDetailsTab"
}

