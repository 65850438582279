import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { GooglemapService } from 'src/app/core/services/googlemap.service';
import { AssetsDetails, GetAssetsBuildingDTO } from 'src/app/Models/Customer';
import { AssetsService } from 'src/app/customer/components/assets/assets.service';

@Component({
  selector: 'app-building-edit-form',
  templateUrl: './building-edit-form.component.html',
  styleUrls: ['./building-edit-form.component.css']
})
export class BuildingEditFormComponent implements OnInit {
  venueId: string;
  assetId: string;
  lng: number;
  lat: number;
  zoom: number = 18;
  strokeColor = "#80cff4"
  isBuildingMapRequired: boolean = false;
  isBuildingSend: boolean = false;
  buildingOptions = [];
  buildingTabDetails = [];
  mainPerimeter = [];
  buildingTabName: string;
  assetsBuildingForm: FormGroup;
  GetAssetsBuildingInfo = new GetAssetsBuildingDTO();

  constructor(
    private _dialogRef: MatDialogRef<BuildingEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private _apiErrorService: ApiErrorService,
    private _googleMapService: GooglemapService,
    private _assetsService: AssetsService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {   
    this.InitForm();
    this.buildingTabDetails = this._initialInfo.dialogInfo.allInfo.area
    this.lat = this._initialInfo.dialogInfo.lat
    this.lng = this._initialInfo.dialogInfo.lng
    this.mainPerimeter = this._initialInfo.dialogInfo.mainPerimeter
    this.assetsBuildingForm.get('buildingType')?.setValue(this._initialInfo.dialogInfo.allInfo.type);
    this.assetsBuildingForm.get('buildingName')?.setValue(this._initialInfo.dialogInfo.allInfo.name);
    this.assetsBuildingForm.get('buildingDescription')?.setValue(this._initialInfo.dialogInfo.allInfo.description);
    this.assetsBuildingForm.get('numberOfFloors')?.setValue(this._initialInfo.dialogInfo.allInfo.numberFloors);
    this.assetsBuildingForm.get('buildingAccess')?.setValue(this._initialInfo.dialogInfo.allInfo.hasPublicAccess);
    this.assetsBuildingForm.get('frequency')?.setValue(this._initialInfo.dialogInfo.allInfo.assetCheckFrequency);
    this.buildingOptions = this._initialInfo.dialogInfo.buildingOptions
    this.venueId = this._initialInfo.dialogInfo.venueId
    this.assetId = this._initialInfo.dialogInfo.allInfo.id
    this.buildingTabName = this._initialInfo.dialogInfo.allInfo.name
  }

  InitForm() {
    this.assetsBuildingForm = this.fb.group({
      buildingType: [null, Validators.required],
      buildingName: [null, Validators.required],
      buildingAccess: [null, Validators.required],
      numberOfFloors: [null, Validators.required],
      buildingDescription: [null, Validators.required],
      frequency: [null, Validators.required]
    });
  }

  get buildingType() {
    return this.assetsBuildingForm.get("buildingType") as FormControl;
  }

  get buildingName() {
    return this.assetsBuildingForm.get("buildingName") as FormControl;
  }

  get buildingAccess() {
    return this.assetsBuildingForm.get("buildingAccess") as FormControl;
  }

  get numberOfFloors() {
    return this.assetsBuildingForm.get("numberOfFloors") as FormControl;
  }

  get buildingDescription() {
    return this.assetsBuildingForm.get("buildingDescription") as FormControl;
  }

  get frequency() {
    return this.assetsBuildingForm.get("frequency") as FormControl;
  }

  resetForm(form: FormGroup, defaultValues?: any) {
    if (defaultValues) {
      form.reset(defaultValues);
    } else {
      form.reset();
    }
  }

  commanBuildingFormSubmission() {
    this.isBuildingSend = true;
      const newData = {
        localityPosition: [...this.GetAssetsBuildingInfo?.localityPosition, ...this.buildingTabDetails],
        ...this.assetsBuildingForm.value
      };

      const payload = {
        id: this.venueId,
        category: 2,
        type: parseInt(newData.buildingType),
        description: newData.buildingDescription,
        area: newData.localityPosition,
        numberFloors: parseInt(newData.numberOfFloors),
        name: newData.buildingName,
        hasPublicAccess: typeof(newData.buildingAccess) === "string" ? newData.buildingAccess === "true" ? true : false : newData.buildingAccess,
        assetCheckFrequency: parseInt(newData.frequency),
      } as AssetsDetails

      this._assetsService.UpdateAssetsTabsDetails(payload, this.venueId, this.assetId).subscribe(
        (response) => {
          if (response) {
            this.resetForm(this.assetsBuildingForm);
            this.isBuildingSend = false;
            this._dialogRef.close(this.assetId);
          }
        },
        (error) => {
          this._apiErrorService.ShowError(error);
          this.isBuildingSend = false;
        }
      );

    return true;
  }

  onSubmitAssetsBuilding() {
    if (this.assetsBuildingForm.invalid || (this.buildingTabDetails.length === 0 && !this.GetAssetsBuildingInfo?.localityPosition?.length)) {
      this.assetsBuildingForm.markAllAsTouched();
      this.isBuildingMapRequired = !this.GetAssetsBuildingInfo?.localityPosition?.length;
      return false;
    }

    this.isBuildingMapRequired = false;
    if (!this.commanBuildingFormSubmission()) {
      return;
    }
  }

  OnMapReadyForBuilding = (map: any) => {
    this._googleMapService.OverlayCompletehandler(map, null, (path) => {
      if (this.GetAssetsBuildingInfo.localityPosition == null) {
        this.GetAssetsBuildingInfo.localityPosition = [];
      }
      this.GetAssetsBuildingInfo.localityPosition.push(this._assetsService.CreatePointArrayForPolygon(path));
    });
  };

  RemoveBuildingPolygon() {
    this.buildingTabDetails = []
    this.GetAssetsBuildingInfo.localityMapReinitial = true;
    this.GetAssetsBuildingInfo.localityPosition = [];
    setTimeout(() => {
      this.GetAssetsBuildingInfo.localityMapReinitial = false;
    }, 10);
  }
}

