<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="custom-container">
                <div class="d-flex align-items-center mb-3 heading-section-with-icon">
                    <div class="heading-section">Edit Parking</div>
                    <img class="info-icon" src="../../assets/images/info-circle-icon.png" alt="info-circle-icon">
                </div>
                <div class="form-style ms-auto me-auto">
                    <form [formGroup]="assetsParkingForm" (ngSubmit)="onSubmitAssetsParking()"
                        class="customer-signup-form">
                        <div class="fieldset">
                            <div class="main-form">
                                <div class="inner-main-form">
                                    <h5 class="mb-4">{{parkingTabName}}</h5>
                                    <div class="mb-4">
                                        <span class="num-circle">1</span>
                                        <div>Edit the type of car park to add.</div>
                                        <div class="select-style">
                                            <select class="form-control" formControlName="parkingType"
                                                class="w-100 select-box">
                                                <option [value]="option.value" *ngFor="let option of parkingOptions">
                                                    {{option.displayName}}</option>
                                            </select>
                                        </div>
                                        <span class="text-danger text-error-message"
                                            *ngIf="parkingType.touched && parkingType.hasError('required')">
                                            Parking type is required
                                        </span>
                                    </div>
                                    <div class="mb-4">
                                        <div>Edit the name</div>
                                        <input formControlName="parkingName" placeholder="Rear car park"
                                            class="form-control textarea-box" required />
                                        <span class="text-danger text-error-message"
                                            *ngIf="parkingName.touched && parkingName.hasError('required')">
                                            Name is required
                                        </span>
                                    </div>
                                    <div class="mb-4">
                                        <span class="num-circle">2</span>
                                        <div>Edit description.</div>
                                        <textarea formControlName="parkingDescription" class="form-control textarea-box"
                                            rows="4" placeholder="e.g. Main car park at the front of the building."
                                            required></textarea>
                                        <span class="text-danger text-error-message"
                                            *ngIf="parkingDescription.touched && parkingDescription.hasError('required')">
                                            Description is required
                                        </span>
                                    </div>
                                    <div class="mb-4">
                                        <span class="num-circle">3</span>
                                        <div>Edit the car park location.</div>
                                        <span class="text-danger text-error-message" *ngIf="isParkingMapRequired">
                                            Map is required
                                        </span>
                                    </div>
                                </div>
                                <div class="modal map-modal-style show">
                                    <div class="w-100">
                                        <div class="map-iframe" *ngIf="!GetAssetsParkingInfo.localityMapReinitial">
                                            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom"
                                                (mapReady)="OnMapReadyForParking($event)" [fullscreenControl]='true'
                                                [mapTypeControl]='true'>
                                                <agm-polygon *ngFor="let path of mainPerimeter" [paths]="path"
                                                    [strokeColor]='strokeColor' fillOpacity="transparent"
                                                    fillColor="transparent">
                                                </agm-polygon>
                                                <agm-polygon *ngFor="let path of
                                                parkingTabDetails" [paths]="path">
                                                </agm-polygon>
                                            </agm-map>
                                        </div>
                                    </div>
                                </div>

                                <div class="inner-main-form mt-3">
                                    <div class="custom-feild">
                                        <div class="label">Edit frequency</div>
                                        <div class="select-style">
                                            <select class="form-control" formControlName="frequency"
                                                class="w-100 select-box">
                                                <option value="1">Weekly</option>
                                                <option value="2">Monthly</option>
                                                <option value="4">Quarterly</option>
                                                <option value="5">6 Monthly</option>
                                                <option value="3">Annual</option>
                                            </select>
                                        </div>
                                    </div>
                                    <span class="text-danger text-error-message"
                                        *ngIf="frequency.touched && frequency.hasError('required')">
                                        Frequency is required
                                    </span>
                                </div>
                                
                                <div class="next-btn inner-main-form">
                                    <div class="d-flex justify-content-between mt-4">
                                        <div>
                                            <button type="button" class="btn btn-danger text-end"
                                                (click)="RemoveParkingPolygon()">Remove</button>
                                        </div>
                                        <div class="hs-submit text-start">
                                            <button type="submit" class="primary-btn">
                                                <div *ngIf="!isParkingSend">
                                                    Save
                                                </div>
                                                <div *ngIf="isParkingSend"
                                                    class="d-flex align-items-center justify-content-center">
                                                    <mat-spinner class="custom-button-spinner" diameter="20"></mat-spinner>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>