<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="custom-container">
                <div class="form-style w-400 ms-auto me-auto">
                    <form [formGroup]="policyCommunicationForm" (ngSubmit)="onSubmitpolicyCommunication()"
                        class="customer-signup-form">
                        <div class="fieldset">
                            <div class="heading-section">Scenario
                            </div>
                            <div class="mb-4">
                                <div>Title</div>
                                <input formControlName="communicationTitle" class="form-control textarea-box"
                                    placeholder="Add title"
                                    required/>
                                <span class="text-danger text-error-message"
                                    *ngIf="communicationTitle.touched && communicationTitle.hasError('required')">
                                Title is required
                                </span>
                            </div>
                            
                            <div class="mb-4">
                                <div>Add a brief description.</div>
                                <textarea formControlName="communicationDescription" class="form-control textarea-box"
                                    rows="4" placeholder="e.g. Locked storage for gas for outside heating."
                                    required></textarea>
                                <span class="text-danger text-error-message"
                                    *ngIf="communicationDescription.touched && communicationDescription.hasError('required')">
                                    Description is required
                                </span>
                            </div>

                            <div class="mb-5">
                                <div>Actions</div>
                                <textarea formControlName="actions" class="form-control textarea-box"
                                    rows="4" placeholder="e.g. Locked storage for gas for outside heating."
                                    required></textarea>
                                <span class="text-danger text-error-message"
                                    *ngIf="actions.touched && actions.hasError('required')">
                                    Actions is required
                                </span>
                            </div>
                        </div>

                        <div class="next-btn">
                            <div class="d-flex flex-column justify-content-between">
                                <div class="hs-submit text-start">
                                    <button type="submit" class="primary-btn">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>