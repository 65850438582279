<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="custom-container">
                <div class="table-header">Activity log</div>
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th class="text-start">Time / date</th>
                                <th class="text-start">Action</th>
                                <th class="text-start"></th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let details of allData; let i=index">
                                <td class="text-start">{{formatDate(details.dateTime)}}</td>
                                <td class="text-start">Asset updated, name change, location change.</td>
                                <td class="text-start">Dave Thornton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>