<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="custom-container">
                <div class="mb-3">
                    <h5 class="mb-1">Considerations</h5>
                    <div *ngFor="let option of data.Considerations">
                        <p>{{option}}</p>
                    </div>
                </div>
                <div class="mb-3">
                    <h5 class="mb-1">Example</h5>
                    <div class="example-box">
                        <div *ngFor="let option of data.Example">
                            <p>{{option}}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={{data.image}} alt="sim-logo">
                </div>
            </div>

        </div>
    </div>
</div>