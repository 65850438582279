import { MapsAPILoader, Marker, MouseEvent } from '@agm/core';
import { Injectable, NgZone } from '@angular/core';
import { ApiErrorService } from './api-error.service';
declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GooglemapService {

  drawingManager: any;
  

  constructor(
    private _apiErrorService: ApiErrorService,
    private _mapAPILoader: MapsAPILoader,
    private _ngZone: NgZone,
  ) { }

  GetCurrentLocation(latlngDiscoveryCallback: any, addressDiscoveryCallback: any){
    if (this.IsGeolocationDisabled()){
      this._apiErrorService.ShowErrorModal("Warning", "Geolocation is not supported by this browser.");
      return;
    }

    this.GetCurrentPosition(0, 0, latlngDiscoveryCallback, addressDiscoveryCallback);
  }

  MarkerDragEnd(m: Marker, $event: MouseEvent, latlngDiscoveryCallback: any, addressDiscoveryCallback: any){
    if (this.IsGeolocationDisabled()){
      this._apiErrorService.ShowErrorModal("Warning", "Geolocation is not supported by this browser.");
      return;
    }

    this.GetCurrentPosition($event.coords.lat, $event.coords.lng, latlngDiscoveryCallback, addressDiscoveryCallback);
  }

  GetCurrentPosition(latitude: number, longitude: number, latlngDiscoveryCallback: any, addressDiscoveryCallback: any){
    navigator.geolocation.getCurrentPosition((position) =>{
      if (!position){
        return;
      }

      latlngDiscoveryCallback(position);

      this._mapAPILoader.load().then(() => {
        let geocoder = new google.maps.Geocoder();
        let latlng: { lat: number, lng: number};
        if (latitude != 0 && longitude != 0){
          latlng = { lat: latitude, lng: longitude };
        }
        else{
          latlng = { lat: position.coords.latitude, lng: position.coords.longitude }
        }
        geocoder.geocode({ location: latlng }, (results: any) => addressDiscoveryCallback(results));
      });
    }, (error) => {
      console.error(error.message);
      this._apiErrorService.ShowErrorModal("Error", "Something went wrong while fetching current position");
    });
  }

  OverlayCompletehandler(map: any, customOptions: any, updatePointsCallback: any){
    let options: any;
    if (customOptions){
      options = customOptions
    }
    else if (google){
      options = { drawingControl: false, drawingControlOptions: { drawingModes: [] }, polygonOptions: { draggable: false, editable: false }, drawingMode: google.maps.drawing.OverlayType.POLYGON };
      
    }
    else{
      options = { drawingControl: false, drawingControlOptions: { drawingModes: [] }, polygonOptions: { draggable: false, editable: false }, drawingMode: "polygon" };
    }

    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
    

    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(paths.getAt(p), "set_at", () => {
              if (!event.overlay.drag) {
                updatePointsCallback(event.overlay.getPath());
              }
            });
            google.maps.event.addListener(paths.getAt(p), "insert_at", () => {
              updatePointsCallback(event.overlay.getPath());
            });
            google.maps.event.addListener(paths.getAt(p), "remove_at", () => {
              updatePointsCallback(event.overlay.getPath());
            });
          }
          updatePointsCallback(event.overlay.getPath());
        }
      }
    );
  }

  OverlayPolylinehandler(map: any, customOptions: any, updatePointsCallback: any){
    let options: any;
    
    if (customOptions){
      options = customOptions
    }
    else if (google){
      options = { drawingControl: false, drawingControlOptions: { drawingModes: [] }, polygonOptions: { draggable: false, editable: false }, drawingMode: google.maps.drawing.OverlayType.POLYLINE};
    }
    else{
      options = { drawingControl: false, drawingControlOptions: { drawingModes: [] }, polygonOptions: { draggable: false, editable: false }, drawingMode: "polyline" };
    }

    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
    
    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYLINE) {
          updatePointsCallback(event.overlay.getPath());
        }
      }
    );
  }

  AutocompleteInitialize(searchBoxElement: HTMLInputElement, placeDiscoveryCallback: any, addressDiscoveryCallback: any){
    this._mapAPILoader.load().then(() => {
      let geoCoder = new google.maps.Geocoder();
      
      const autocomplete = new google.maps.places.Autocomplete(searchBoxElement);
      autocomplete.addListener("place_changed", () => {
        this._ngZone.run(() => {
          //get the place result
          const place = autocomplete.getPlace();
        
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          placeDiscoveryCallback(place);
        
          let latlng = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
        
          geoCoder.geocode({ location: latlng }, (addresses) => addressDiscoveryCallback(addresses, place));
        });
      });
    });
  }

  GetCoordinatesFromPostcode(postcode: string, resultCallback: any) {
    this._mapAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: postcode }, (results, status) => {
        if (status === 'OK') {
          resultCallback(results[0].geometry.location.lat(), results[0].geometry.location.lng());
          // this.lat = results[0].geometry.location.lat();
          // this.lng = results[0].geometry.location.lng();
        } else {
          console.error(`Geocoding failed: ${status}`);
        }
      });
    });
  }

  MapClicked($event: MouseEvent, addressDiscoveryCallback: any){
    this._mapAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder();
      let latlng = {
        lat: $event.coords.lat,
        lng: $event.coords.lng,
      };
      geocoder.geocode(
        {
          location: latlng,
        },
        (result) => addressDiscoveryCallback(result)
      );
    });
  }


  //#region Helpers

  IsGeolocationDisabled(){
    if (!navigator.geolocation){
      return true;
    }
    else{
      return false;
    }
  }

  GetPostCodeAndFormattedAddress(addressComponents: any): {postCode: string, formattedAddress: string}{
    return {postCode: addressComponents[1].address_components[addressComponents[1].address_components.length - 1].long_name, formattedAddress: addressComponents[0].formatted_address};
  }

  //#endregion
}
