<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="custom-container">
                <div class="d-flex align-items-center mb-3 heading-section-with-icon">
                    <div class="heading-section">Edit Street furniture
                        & infrastructure</div>
                    <img class="info-icon" src="../../assets/images/info-circle-icon.png" alt="info-circle-icon">
                </div>
                <div class="form-style w-400 ms-auto me-auto">
                    <form [formGroup]="assetsFurnitureForm" (ngSubmit)="onSubmitAssetsFurniture()">
                        <div class="fieldset">
                            <div class="main-form">
                                <div class="inner-main-form">
                                    <div class="mb-4">
                                        <span class="num-circle">1</span>
                                        <div>Edit the type of street furniture
                                            & infrastructure to add.</div>
                                        <div class="select-style">
                                            <select class="form-control" formControlName="furnitureType"
                                                class="w-100 select-box">
                                                <option [value]="option.value" *ngFor="let option of furnitureOptions">
                                                    {{option.displayName}}</option>
                                            </select>
                                        </div>
                                        <span class="text-danger text-error-message"
                                            *ngIf="furnitureType.touched && furnitureType.hasError('required')">
                                            Furniture type is required
                                        </span>
                                    </div>
                                    <div class="mb-4">
                                        <span class="num-circle">2</span>
                                        <div>Edit description.</div>
                                        <textarea formControlName="furnitureDescription"
                                            class="form-control textarea-box" rows="4"
                                            placeholder="e.g. Front entrance main light." required></textarea>
                                        <span class="text-danger text-error-message"
                                            *ngIf="furnitureDescription.touched && furnitureDescription.hasError('required')">
                                            Description is required
                                        </span>
                                    </div>
                                    <div class="mb-4">
                                        <span class="num-circle">3</span>
                                        <div>Edit the location.</div>
                                        <span class="text-danger text-error-message" *ngIf="isFurnitureMapRequired">
                                            Map is required
                                        </span>
                                       
                                    </div>
                                </div>
                                <div class="modal map-modal-style show">
                                    <div class="w-100">
                                        <div class="map-iframe" *ngIf="!GetAssetsFurnitureInfo.localityMapReinitial">
                                            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom"
                                                [fullscreenControl]='true' [mapTypeControl]='true'>
                                                <agm-polygon *ngFor="let path of mainPerimeter" [paths]="path"
                                                    [strokeColor]='strokeColor' fillOpacity="transparent"
                                                    fillColor="transparent">
                                                </agm-polygon>
                                                <agm-marker [latitude]="furnitureTabDetails[0][0]?.lat"
                                                    [longitude]="furnitureTabDetails[0][0]?.lng"
                                                    [iconUrl]="{url: BLUE_MARKER_URL}" [markerDraggable]="true"
                                                    (dragEnd)="markerDragEnd(marker, $event)">
                                                </agm-marker>
                                            </agm-map>
                                        </div>
                                    </div>
                                </div>

                                <div class="inner-main-form mt-3">
                                    <div class="custom-feild">
                                        <div class="label">Edit frequency</div>
                                        <div class="select-style">
                                            <select class="form-control" formControlName="frequency"
                                                class="w-100 select-box">
                                                <option value="1">Weekly</option>
                                                <option value="2">Monthly</option>
                                                <option value="4">Quarterly</option>
                                                <option value="5">6 Monthly</option>
                                                <option value="3">Annual</option>
                                            </select>
                                        </div>
                                    </div>
                                    <span class="text-danger text-error-message"
                                        *ngIf="frequency.touched && frequency.hasError('required')">
                                        Frequency is required
                                    </span>
                                </div>
                                
                                <div class="next-btn inner-main-form">
                                    <div class="d-flex justify-content-end mt-4">
                                        <div class="hs-submit text-start">
                                            <button type="submit" class="primary-btn">
                                                <div *ngIf="!isFurnitureSend">
                                                    Save
                                                </div>
                                                <div *ngIf="isFurnitureSend"
                                                    class="d-flex align-items-center justify-content-center">
                                                    <mat-spinner class="custom-button-spinner" diameter="20"></mat-spinner>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>