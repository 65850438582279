<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="custom-container">
                <div class="form-style w-400 ms-auto me-auto accordianHeight">
                    <form (ngSubmit)="onSubmitPolicyEvacuation()" class="customer-signup-form">
                        <div class="fieldset">
                            <div class="heading-section">{{title}}</div>

                            <!-- Main Map -->
                            <div class="map-iframe" *ngIf="!isPin">
                                <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom"
                                    (mapReady)="OnMapReadyForEvacuation($event)" [fullscreenControl]='true'
                                    [mapTypeControl]='true'>
                                </agm-map>
                                <span class="text-danger text-error-message" *ngIf="isEvacuationMapRequired">
                                    lines is required
                                </span>
                                <span class="text-danger text-error-message" *ngIf="isEvacuationMapLengthLimit">
                                    Only one line allowed.
                                </span>
                            </div>

                            <div class="map-iframe" *ngIf="isPin">
                                <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [fullscreenControl]='true'
                                    [mapTypeControl]='true'>
                                    <agm-marker [latitude]="lat" [longitude]="lng" [iconUrl]="{url: BLUE_MARKER_URL}"
                                        [markerDraggable]="true" (dragEnd)="markerDragEnd(marker, $event)">
                                    </agm-marker>
                                </agm-map>
                                <span class="text-danger text-error-message" *ngIf="isEvacuationMapRequired">
                                    Pin is required
                                </span>

                            </div>

                        </div>

                        <div class="next-btn">
                            <div class="d-flex flex-column justify-content-between">
                                <div class="hs-submit text-start">
                                    <button type="submit" class="primary-btn">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>