<div class="header-dashboard" #headerDashboard>
  <div [class]="navmenuClass">
    <div class="sen-in d-flex flex-wrap align-items-center
      justify-content-between">
      <div class="header-logo">
        <div class="logo-one">
          <img src="../../assets/images/vru-logo1.png" alt="vru-logo">
        </div>
        <div class="logo-two">
          <img src="../../assets/images/sim-logo1.png" alt="sim-logo">
        </div>
      </div>
      <ul class="help-div admin-box">
        

        <!-- <li>
          <div *ngIf="isSignedIn">
            <span class="m-2">{{currentlyLoggedInUsers}}</span>
            <button class="btn btn-danger m-2" (click)="btnSignoutClicked()">Signout</button>
          </div>
        </li> -->

        <!-- <li>
          <a [routerLink]="['/response-actions-pre-protocol-launch']" class="d-inline-flex align-items-center">
            <span class="admin-name d-block">Response Actions (Pre Protocol Launch)</span>
            <img src="../../assets/images/profile-ah-icon.png" alt="profile-ah-icon">
          </a>
        </li> -->
        <!-- <li>
          <a [routerLink]="['/protocol-actions-post-protocol-launch']" class="d-inline-flex align-items-center">
            <span class="admin-name d-block">Protocol Actions (Post Protocol Launch)</span>
            <img src="../../assets/images/profile-ah-icon.png" alt="profile-ah-icon">
          </a>
        </li> -->
        <!-- <li>
          <a [routerLink]="['/incident-detail/7f02a1c7-cea7-4d10-e99f-08dab6684454']" class="d-inline-flex align-items-center">
            <span class="admin-name d-block">Incident Summary Pre Protocol Launch</span>
            <img src="../../assets/images/profile-ah-icon.png" alt="profile-ah-icon">
          </a>
        </li> -->


        <!-- <li>
          <div class="d-inline-flex align-items-center">
            <span class="help-title">HELP!</span>
            <a class="d-inline-flex align-items-center" [href]="currentPageHelpUrl" target="_blank">
              <span class="admin-name d-block"> This page</span>
              <img src="assets/images/help1.png" alt="profile-ah-icon">
            </a>
            <a class="d-inline-flex align-items-center" [href]="simHelpUrl" target="_blank">
              <span class="admin-name d-block"> SIM</span>
              <img src="assets/images/help1.png" alt="profile-ah-icon">
            </a>
            <span
            class="sidebar_closebtn1" (click)="SidebarCloseBtnClicked(headerDashboard)">
            <div (click)="HamburgToggle($event, headerDashboard)" class="humburg"> <span></span>
              <span></span>
              <span></span>
           </div>
          </span>
        </div>
        </li> -->

        <li>
          <div class="d-inline-flex align-items-center">
            <a class="d-inline-flex align-items-center" 
              [href]="currentPageHelpUrl" 
              target="_blank">
              <span class="admin-name d-block help-Text"> Help</span>
              <img src="assets/images/help1.png" alt="profile-ah-icon">
            </a>
            <span
            class="sidebar_closebtn1" (click)="SidebarCloseBtnClicked(headerDashboard)">
            <div (click)="HamburgToggle($event, headerDashboard)" class="humburg"> <span></span>
              <span></span>
              <span></span>
           </div>
          </span>
        </div>
        </li>

      </ul>

      <div class="sidebar flex-shrink-0 p-3 bg-white" style="width: 280px;">
        <span class="d-flex align-items-center pb-2 mb-2 link-dark text-decoration-none border-bottom">
          <img class="profile-image" [src]="currentUser && currentUser.avatar ? currentUser.avatar : 'assets/images/profile-blank-icon.png'" alt="profile-ah-icon">
          <span class="admin-name d-block"> {{currentUser?.firstName + ' ' + currentUser?.familyName}}</span>
        </span>  
        <span class="sidebar_closebtn showHand" (click)="SidebarCloseBtnClicked(headerDashboard)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="close"><path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg></span>
        <ul class="list-unstyled ps-0">
          

          <li class="mb-1" *ngIf="isBCUUser && !isLaUser && currentPageUrl !== _urlHelperService.BcuUserDashboardUrl()">
            <div class="d-inline-flex align-items-center showHand" (click)="BCUDashboardClicked()">
                <span class="admin-name d-block">Dashboard</span>
            </div>
          </li>

          <li class="mb-1">
            <div class="d-inline-flex align-items-center showHand">
                <a target="_blank" 
                  [href]="isBCUUser && !isLaUser ? 'https://help.seriousincidentmanager.com/police-control-room' : 'https://help.seriousincidentmanager.com/local-authorities/'">
                  <span class="admin-name d-block">Help</span>
                </a>
            </div>
          </li>

          <li class="mb-1" *ngIf="!isBCUUser && isLaUser && currentPageUrl !== _urlHelperService.LaUserDashboardUrl()">
            <div class="d-inline-flex align-items-center showHand" (click)="LaHomeDashboardClicked()">
              <span class="admin-name d-block">Dashboard</span>
            </div>
          </li>

          <!-- <li class="mb-1" *ngIf="!isBCUUser && isLaUser" >
            <div class="d-inline-flex align-items-center showHand"  (click)="LaDashboardClicked()">
              <span class="admin-name d-block">Local Authority</span>
            </div>
          </li> -->

          <li class="mb-1" *ngIf="isBCUUser || isLaUser">
            <div class="d-inline-flex align-items-center showHand" (click)="ChangePasswordClicked()">
                <span class="admin-name d-block">Change password</span>
            </div>
          </li>
          

          <li class="mb-1">
            <button class="btn btn-color m-2" (click)="btnSignoutClicked()">Sign out</button>

            <!-- <a href="#" class="btn  align-items-center rounded">
              Signout
            </a>     -->
          </li>

          </ul>
      </div>

    </div>
  </div>
</div>
