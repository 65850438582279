import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ChecksDeatils } from 'src/app/Models/Customer';

@Component({
  selector: 'app-activity-log-form',
  templateUrl: './activity-log-form.component.html',
  styleUrls: ['./activity-log-form.component.css']
})
export class ActivityLogFormComponent implements OnInit {
  allData: ChecksDeatils

  constructor(
    private _dialogRef: MatDialogRef<ActivityLogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
  ) { }

  ngOnInit(): void {
    this.allData = this._initialInfo.dialogInfo.allInfo
  }

  formatDate(inputDate:string) {
    const date = new Date(inputDate);

    const options: Intl.DateTimeFormatOptions = { 
        year: 'numeric', 
        month: 'long', 
        day: '2-digit' 
    };
    
    const formattedDate = date.toLocaleDateString('en-US', options);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    hours = hours % 12;
    hours = hours ? hours : 12; 
    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;

    return `${formattedDate} ${formattedTime}`;
}
}
