<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="heading-section">Evacuation Info</div>
            <div class="custom-container">
                <div class="form-style w-400 ms-auto me-auto accordianHeight">
                    <form [formGroup]="policyEvacuationForm" (ngSubmit)="onSubmitPolicyEvacuation()"
                        class="customer-signup-form">
                        <div class="fieldset">
                            <div class="main-form">
                                <div class="main-building-title"><span>{{assetData.typeName}}</span>
                                    {{numberToWord(assetData.numberFloors)}} floor </div>

                                <p class="max-w">Draw an evacuation route to take people away
                                    from the threat. </p>
                                <span class="text-danger text-error-message" *ngIf="isEvacuationMapRequired">
                                    Route is required
                                </span>
                                <span class="text-danger text-error-message" *ngIf="isEvacuationMapLengthLimit">
                                    Only one route allowed.
                                </span>
                                <div class="my-3">
                                    <div>Add evacuation route description</div>
                                    <textarea formControlName="evacuationDescription" class="form-control textarea-box"
                                        rows="3"
                                        placeholder="e.g. Use automated alerting systemLorem ipsum dolor sit amet, consectetuer adipiscing"
                                        required></textarea>
                                    <span class="text-danger text-error-message"
                                        *ngIf="evacuationDescription.touched && evacuationDescription.hasError('required')">
                                        Description is required
                                    </span>
                                </div>
                                <div class="next-btn mb-0 mt-5">
                                    <div class="d-flex flex-column justify-content-between">
                                        <div class="hs-submit text-start">
                                            <button type="submit" class="primary-btn">
                                                <div *ngIf="!isEvacuationSend">
                                                    Save
                                                </div>
                                                <div *ngIf="isEvacuationSend"
                                                    class="d-flex align-items-center justify-content-center">
                                                    <mat-spinner class="custom-button-spinner" diameter="20"></mat-spinner>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- Main Map -->
                <div class="modal map-modal-style show">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body d-flex align-items-center">
                                <div class="w-100">
                                    <div class="map-iframe" *ngIf="!GetPolicyEvacuationInfo.localityMapReinitial">
                                        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom"
                                            (mapReady)="OnMapReadyForEvacuation($event)" [fullscreenControl]='true'
                                            [mapTypeControl]='true'>
                                            <agm-polygon *ngFor="let path of mainPerimeter" [paths]="path"
                                                [strokeColor]='strokeColor' fillOpacity="transparent"
                                                fillColor="transparent">
                                            </agm-polygon>
                                            <agm-polyline [strokeColor]="'#2196f3'">
                                                <agm-polyline-point *ngFor="let point of evacuationTabDetails"
                                                    [latitude]="point.lat" [longitude]="point.lng">
                                                </agm-polyline-point>
                                                <agm-icon-sequence [strokeColor]="'green'" [fillColor]="'green'"
                                                    [repeat]="'8px'" [offset]="0" [strokeWeight]="3" [scale]="2"
                                                    [strokeOpacity]="100" [path]="'M 0,-1 L -1,0 L 1,0 z'">
                                                </agm-icon-sequence>
                                            </agm-polyline>
                                        </agm-map>
                                        <div class="d-flex justify-content-end mt-2" *ngIf="isEdit">
                                            <button type="button" class="btn btn-danger text-end"
                                                (click)="RemoveEvacuationPolygon()">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>