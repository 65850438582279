import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { GooglemapService } from 'src/app/core/services/googlemap.service';
import { GetPolicyEvacuationDTO, PoliciesDetails } from 'src/app/Models/Customer';
import { PoliciesService } from "src/app/customer/components/policies/policies.service";
import { CustomerService } from "src/app/customer/customer.service";
import { DrawLinesAddFormComponent } from "../draw-lines-add-form/draw-lines-add-form.component";

@Component({
  selector: 'app-invacuation-add-edit-form',
  templateUrl: './invacuation-add-edit-form.component.html',
  styleUrls: ['./invacuation-add-edit-form.component.css']
})
export class InvacuationAddEditFormComponent implements OnInit {
  SAFE_PLACE_MARKER_URL = "../assets/images/pink-marker-icon.png";
  LOCKABLE_DOOR_MARKER_URL = "../assets/images/lockdown-icon.png";
  venueId: string;
  assetId: string;
  invacuationRouteId: string;
  isEdit: boolean = false;
  lng: number;
  lat: number;
  zoom: number = 18;
  strokeColor = "#80cff4"
  isInvacuationMapRequired: boolean = false;
  isInvacuationMapLengthLimit: boolean = false;
  invacuationTabDetails = [];
  mainPerimeter = []
  assetData:any = []
  policyInvacuationForm: FormGroup;
  GetPolicyInvacuationInfo = new GetPolicyEvacuationDTO();

  safePlacePinData = []
  lockableDoorPinData = []

  constructor(
    private _dialogRef: MatDialogRef<InvacuationAddEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private _apiErrorService: ApiErrorService,
    private _googleMapService: GooglemapService,
    private _policiesService: PoliciesService,
    private _customerService: CustomerService,
    private fb: FormBuilder,
    private _dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.venueId = this._initialInfo.dialogInfo.venueId
    this.isEdit = this._initialInfo.dialogInfo.isEdit
    this.assetId = this._initialInfo.dialogInfo.assetData.id
    this.assetData = this._initialInfo.dialogInfo.assetData
    this.InitForm();

    this.lat = this._initialInfo.dialogInfo.lat
    this.lng = this._initialInfo.dialogInfo.lng
    this.mainPerimeter = this._initialInfo.dialogInfo.mainPerimeter

    if (this.isEdit) {
      this.policyInvacuationForm.get('invacuationDescriptionOne')?.setValue(this._initialInfo.dialogInfo.allInfo.description);
      this.policyInvacuationForm.get('invacuationDescriptionTwo')?.setValue(this._initialInfo.dialogInfo.allInfo.description);
      this.policyInvacuationForm.get('invacuationDescriptionThree')?.setValue(this._initialInfo.dialogInfo.allInfo.description);
      this.invacuationTabDetails = this._initialInfo.dialogInfo.allInfo.routePoints;
      this.invacuationRouteId = this._initialInfo.dialogInfo.allInfo.id;
    }
  }

  InitForm() {
    this.policyInvacuationForm = this.fb.group({
      invacuationDescriptionOne: [null, Validators.required],
      invacuationDescriptionTwo: [null, Validators.required],
      invacuationDescriptionThree: [null, Validators.required],
      noSafeSpaces: [null, Validators.required],
    });
  }

  get invacuationDescriptionOne() {
    return this.policyInvacuationForm.get("invacuationDescriptionOne") as FormControl;
  }

  get invacuationDescriptionTwo() {
    return this.policyInvacuationForm.get("invacuationDescriptionTwo") as FormControl;
  }

  get invacuationDescriptionThree() {
    return this.policyInvacuationForm.get("invacuationDescriptionThree") as FormControl;
  }

  get noSafeSpaces() {
    return this.policyInvacuationForm.get("noSafeSpaces") as FormControl;
  }

  numberToWord(n: number): string {
    return this._customerService.numberToWord(n);
  }

  resetForm(form: FormGroup, defaultValues?: any) {
    if (defaultValues) {
      form.reset(defaultValues);
    } else {
      form.reset();
    }
  }

  onUpdateSafePlacePin() {
    this._dialog
      .open(DrawLinesAddFormComponent, {
        height: "auto",
        width: "auto",
        data: {
          dialogInfo: {
            lat: this.lat,
            lng: this.lng,
            zoom: this.zoom,
            isPin: true,
            title: "Add safe place"
          }
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.safePlacePinData.push(data.localityPosition[0][0])
      });
  }

  onUpdateLockableDoorsPin() {
    this._dialog
      .open(DrawLinesAddFormComponent, {
        height: "auto",
        width: "auto",
        data: {
          dialogInfo: {
            lat: this.lat,
            lng: this.lng,
            zoom: this.zoom,
            isPin: true,
            title: "Add lockable door"
          }
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.lockableDoorPinData.push(data.localityPosition[0][0])
      });
  }

  commanInvacuationFormSubmission() {
    const newData = {
      localityPosition: [...this.GetPolicyInvacuationInfo?.localityPosition],
      ...this.policyInvacuationForm.value
    };
    
    if(this.GetPolicyInvacuationInfo?.localityPosition?.length > 1){
      this.isInvacuationMapRequired = true
      return false;
    }

    const payload = {
      id: this.venueId,
      description: newData.invacuationDescription,
      routePoints: this.isEdit ? this.invacuationTabDetails.length > 0 ? this.invacuationTabDetails : newData.localityPosition[0] : newData.localityPosition[0],
    } as PoliciesDetails

      // this._policiesService.UpdateInvacuationDetails(payload, this.venueId, this.assetId, this.invacuationRouteId).subscribe(
      //   (response) => {
      //     if (response) {
      //       this.resetForm(this.policyInvacuationForm);
      //       this._dialogRef.close(this.assetId);
      //     }
      //   },
      //   (error) => {
      //     this._apiErrorService.ShowError(error);
      //   }
      // );
    return true;
  }

  onSubmitPolicyInvacuation() {
    if (this.policyInvacuationForm.invalid || (this.invacuationTabDetails.length === 0 && !this.GetPolicyInvacuationInfo?.localityPosition?.length)) {
      this.policyInvacuationForm.markAllAsTouched();
      this.isInvacuationMapRequired = !this.GetPolicyInvacuationInfo?.localityPosition?.length;
      return false;
    }

    if(this.GetPolicyInvacuationInfo?.localityPosition?.length > 1){
      this.isInvacuationMapLengthLimit = true
      return false;
    }

    this.isInvacuationMapRequired = false;
    this.isInvacuationMapLengthLimit = false;
    if (!this.commanInvacuationFormSubmission()) {
      return;
    }
  }
}

