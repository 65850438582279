import { Marker, MouseEvent } from '@agm/core';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IncidentFormLocationEntity } from 'src/app/Models/IncidentFormLocationEntity';
import { MapForm } from 'src/app/Models/MapForm';
import { Markers, LocationDTO } from 'src/app/Models/incident.modal';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { GooglemapService } from 'src/app/core/services/googlemap.service';
import { StringHelperService } from 'src/app/core/services/string-helper.service';
import { RoomAssetsName } from 'src/app/Enum/RoomAssets.enum';
import { RoomAssetsAddEditFormService } from './room-assets-add-edit-form.service';

@Component({
  selector: 'app-room-assets-add-edit-form',
  templateUrl: './room-assets-add-edit-form.component.html',
  styleUrls: ['./room-assets-add-edit-form.component.css']
})
export class RoomAssetsAddEditFormComponent implements OnInit {
  @ViewChild('mapForm', { static: true }) mapForm: NgForm;

  get FirstAidKits() {
    return RoomAssetsName.FirstAidKits;
  }
  get GrabBags() {
    return RoomAssetsName.GrabBags;
  }
  drawingType: string;
  mapData: MapForm = new MapForm();
  isDuplicateName: boolean;
  isEdit: boolean;
  id: string;
  isInvalidMap: boolean = false;
  isMapReady = false;
  isSubmitted = false;
  incidentId: string;
  InitialLatitude: number;
  InitialLongitude: number;
  InitialZoom: number;
  markerIconUrl: string;
  color: string;
  closed: boolean;
  marker: Markers;
  drawingManager: any;

  pointList: any[] = [];
  existingAllPolygon: IncidentFormLocationEntity[] = [];
  existingRVPMarkers: IncidentFormLocationEntity[] = [];
  LocationToBeUpdate: IncidentFormLocationEntity;
  editable = false;
  polygonDrawn: boolean = false;
  RVPLatitude: number;
  RVPLongitude: number;
  HeadingText: string = "";
  strokeColor = "#80cff4"
  //#endregion

  constructor(
    private _dialogRef: MatDialogRef<RoomAssetsAddEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private _roomAssetsAddEditFormService: RoomAssetsAddEditFormService,
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService,
    private _stringHelperService: StringHelperService,
    private _googleMapService: GooglemapService
  ) { }

  ngOnInit(): void {
    this.incidentId = this._initialInfo.incidentId;
    if (!this._stringHelperService.IsValidGuid(this.incidentId)) {
      this._dialogRef.close();
    }

    this.InitialLatitude = this._initialInfo.latitude;
    this.InitialLongitude = this._initialInfo.longitude;
    this.InitialZoom = this._initialInfo.zoom;
    this.drawingType = this._initialInfo.drawingType;



    this.color = this._initialInfo.color;
    this.existingAllPolygon = this._initialInfo.existingAllPolygon
    this.existingRVPMarkers = this._initialInfo.existingRVPMarkers
    this.isEdit = this._initialInfo.isEdit;
    this.LocationToBeUpdate = this._initialInfo.LocationToBeUpdate;
    this.id = this._initialInfo.id;
    this.closed = this._initialInfo.closed ?? false;
    this.marker = this._initialInfo.marker;

    this.mapData.Name = this.LocationToBeUpdate?.name;
    this.mapData.Description = this.LocationToBeUpdate?.description;

    if (this.marker) {
      let cordinatesArray = [];
      cordinatesArray.push({ lat: this.marker.lat, lng: this.marker.lng });
      this.pointList.push(cordinatesArray);
    }
    else {
      if (this.isEdit) {
        const array = [...this.LocationToBeUpdate?.pathList];
        this.pointList.push(array);
      }
    }

    this.isMapReady = true;
  }


  //#region polygon

  onMapReadyForCrimeSceneOnlyShowMap($event: any) {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: "polygon",
    };

    this._googleMapService.OverlayCompletehandler($event, options, (path) => {
      const latLngArray = this._roomAssetsAddEditFormService.CreatePointArrayForPolygon(path);
    
      this.pointList.push(latLngArray);
      this.editable = false;
      this.isInvalidMap = false;
    });
  }

  //#endregion

  markerDragEnd(m: Marker, $event: MouseEvent) {
    this.pointList[0][0].lat = $event.coords.lat;
    this.pointList[0][0].lng = $event.coords.lng;

    this.isInvalidMap = false;
  }

  markerDragEndLocation($event: MouseEvent) {
    let path = $event.coords;

    this.pointList.forEach((element) => {
      element.LocationDTOItem.forEach((sub) => {
        if (sub.mapShow) {
          sub.selected = true;
          sub.locationPoints = [];
          sub.locationPoints.push(path);
        }
      });
    });
  }

  RemoveLocations() {
    this.pointList = [];
    this.isInvalidMap = true
  }

  onSubmit(formData: MapForm) {
    this.isSubmitted = true;
    // if (!this.pointList || this.pointList.length == 0){
    //   this.isInvalidMap = true;
    //   return;
    // }

    // document.getElementById('save-spinner').style.display = 'block';
    // document.getElementById('btn-save').style.display = 'none';

    let result = {
      id: this.isEdit ? "e27e7372-51e1-4ecd-c5ec-08dc4a878bfa" : null,
      name: formData.Name,
      description: formData.Description,
      locations: this.pointList[0],
      color: this.color,
      locationDTO: new LocationDTO()
    };

    if (this.isEdit) {
      result.id = this.id;
    }

    let locationDTO = new LocationDTO();
    locationDTO.type = this._roomAssetsAddEditFormService.LocationTypeDesider(this.drawingType);
    locationDTO.name = result.name;
    locationDTO.closed = false;
    locationDTO.description = result.description;
    locationDTO.locationPoints = this.pointList[0];

    if (this.isEdit) {
      locationDTO.id = this.id;
      locationDTO.closed = this.closed;
    }
    result.locationDTO = locationDTO;

    this._dialogRef.close(result);


    // this._roomAssetsAddEditFormService.saveUpdateLocation(locationDTO, this.incidentId).subscribe(response => {
    //   document.getElementById('save-spinner').style.display = 'none';
    //   document.getElementById('btn-save').style.display = 'block';

    //   if (response && response.statusCode == 200 && response.data){
    //     result.locationDTO.id = response.data;
    //     result.id = response.data;
    //     this._dialogRef.close(result);
    //   }
    // },
    // (error) => {
    //   document.getElementById('save-spinner').style.display = 'none';
    //   document.getElementById('btn-save').style.display = 'block';
    //   this._apiErrorService.ShowError(error);
    // });


  }
}
