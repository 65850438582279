<div class="py-50 message-form member_add">
  <div class="container">
    <div class="accordion tableaccordian" id="addmember">
      <div class="accordion-item">
        <h2 class="accordion-header" id="addmemberheading1">
          <div class="accordion-button" type="button">
            Add team member
          </div>
        </h2>
        <form [formGroup]="teamMemberForm" (submit)="onSubmit()">
          <div id="addmember1" class="accordion-collapse collapse show" aria-labelledby="addmemberheading11"
            data-bs-parent="#addmember">
            <div class="accordion-body">
              <div class="form-style">
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input select">
                          <img class="hs_icon" src="assets/images/user.png" alt="user">
                          <select name="AllTeamMembers" formControlName="role" id="teammembers">
                            <option [ngValue]="null">Select team member role</option>
                            <option ngValue="GroupAdmin">Admin</option>
                            <option ngValue="GroupMember">Team Member</option>
                            <option ngValue="GroupCommunicator">billing/super user</option>
                          </select>
                        </div>

                        <span class="text-danger" *ngIf="role.touched && role.hasError('required')">
                          Role is required</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user.png" alt="user">
                          <input class="form-control" type="text" id="firstName" placeholder="First name"
                            formControlName="firstName">
                        </div>

                        <span class="text-danger" *ngIf="firstName.touched && firstName.hasError('required')">
                          First name is required</span>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user.png" alt="user">
                          <input class="form-control" type="text" id="givenName" placeholder="Given name"
                            formControlName="givenName">
                        </div>

                        <span class="text-danger" *ngIf="givenName.touched && givenName.hasError('required')">
                          Given name is required</span>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user-rol.png" alt="user-rol">
                          <input class="form-control" type="text" id="jobTitle" placeholder="Role"
                            formControlName="jobTitle">
                        </div>

                        <span class="text-danger" *ngIf="jobTitle.touched && jobTitle.hasError('required')">
                          Job Title is required</span>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user-id.png" alt="user-id">
                          <input class="form-control" type="text" id="employer" placeholder="Employer"
                            formControlName="employer">
                        </div>

                        <span class="text-danger" *ngIf="employer.touched && employer.hasError('required')">
                          Employer is required</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/Local_Authority_blue.png"
                            alt="Local_Authority_blue">
                          <input class="form-control" type="tel" id="phone" placeholder="Mobile"
                            formControlName="phone">
                        </div>

                        <div class="text-danger" *ngIf="phone.touched && phone.hasError('required')">
                          Mobile is required</div>
                        <div class="text-danger" *ngIf="phone.touched && phone.hasError('pattern')">
                          Invalid mobile number (formate example: '+1234567890')</div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/blue-mail-icon.png" alt="blue-mail-icon">
                          <input class="form-control" type="mail" id="Email" placeholder="Email"
                            formControlName="email">
                        </div>

                        <span class="text-danger" *ngIf="email.touched && email.hasError('required')">
                          Email is required</span>
                        <span class="text-danger" *ngIf="email.touched && email.hasError('email')">
                          Invalid Email id</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hs-submit p-0 d-flex justify-content-end">
                  <button type="submit" class="primary-btn">Save</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>