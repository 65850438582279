import { ToastrService } from 'ngx-toastr';
import { MemberType } from '../../Enum/MemberType.enum';
import { TeamMember } from '../../Models/Team-member/TeamMemberDTO';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRole } from 'src/app/Enum/UserRole.enum';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { PeopleService } from 'src/app/customer/components/people/people.service';
import { AppStartService } from 'src/app/core/services/app-start.service';

@Component({
  selector: 'app-people-add-edit-form',
  templateUrl: './people-add-edit-form.component.html',
  styleUrls: ['./people-add-edit-form.component.css']
})
export class PeopleAddEditFormComponent implements OnInit {
  teamMemberForm: FormGroup;
  isSubmitted: boolean;
  isEdit: boolean = false;
  venueId: string;

  constructor(
    private _dialogRef: MatDialogRef<PeopleAddEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _fb: FormBuilder,
    private _peopleService: PeopleService,
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService,
    private _userProfileService: UserProfileService,
    private _appStartService: AppStartService,
  ) { }

  ngOnInit(): void {
    // if (this._data.memberProfile == null) {
    //   this.isEdit = false;
    // }
    // else {
    //   this.isEdit = true;
    // }

    this.InitForm();
    this.venueId = this._appStartService.currentUser?.venues?.[0]?.id;
    // if (this.isEdit) {
    //   let isIncluded = false;
    //   if (this._data.memberProfile.role) {
    //     isIncluded = [UserRole[UserRole.GroupAdmin], UserRole[UserRole.GroupMember], UserRole[UserRole.GroupCommunicator]].includes(this._data.memberProfile.role);
    //   }

    //   this.teamMemberForm.patchValue({
    //     firstName: this._data.memberProfile.firstName,
    //     givenName: this._data.memberProfile.givenName,
    //     role: isIncluded ? this._data.memberProfile.role : null,
    //     employer: this._data.memberProfile.employer,
    //     jobTitle: this._data.memberProfile.jobTitle,
    //     phone: this._data.memberProfile.phone,
    //     email: this._data.memberProfile.email,
    //   });
    // }
  }

  InitForm() {
    this.teamMemberForm = this._fb.group({
      firstName: [null, Validators.required],
      givenName: [null, Validators.required],
      role: [null, Validators.required],
      employer: [null, Validators.required],
      phone: [null, [Validators.required, Validators.pattern(/^\+[1-9]\d{1,14}$/)]],
      email: [null, [Validators.required, Validators.email]],
      jobTitle: [null],
    });
  }

  //#region form-getters

  get firstName() {
    return this.teamMemberForm.get("firstName") as FormControl;
  }

  get givenName() {
    return this.teamMemberForm.get("givenName") as FormControl;
  }

  get role() {
    return this.teamMemberForm.get("role") as FormControl;
  }

  get employer() {
    return this.teamMemberForm.get("employer") as FormControl;
  }

  get phone() {
    return this.teamMemberForm.get("phone") as FormControl;
  }

  get email() {
    return this.teamMemberForm.get("email") as FormControl;
  }

  get jobTitle() {
    return this.teamMemberForm.get("jobTitle") as FormControl;
  }

  //#endregion

  onSubmit() {
    this.isSubmitted = true;
    if (this.teamMemberForm.invalid) {
      this.teamMemberForm.markAllAsTouched();
      return false;
    }

    let teamMember = new TeamMember();
    teamMember.familyName = this.givenName.value;
    teamMember.firstName = this.firstName.value;
    teamMember.email = this.email.value;
    teamMember.phoneNo = this.phone.value;
    teamMember.employer = this.employer.value;
    // teamMember.memberType = MemberType.Member;
    // teamMember.roles = +UserRole[this.role.value];
    teamMember.roles = 10;
    teamMember.jobTitle = this.jobTitle.value;
    // teamMember.avatar = this._data?.memberProfile?.avatar;
    // teamMember.MemberCategoryId = 26;

    // teamMember.groups = this._userProfileService.GetAuthenticatedUserGroup()?.organizationGroupId;

    if (this.isEdit) {
      teamMember.id = this._data.memberProfile.id;
    }
    // teamMember.sendJoiningEmail = true;
    if (this.isEdit) {
      this._peopleService.EditTeamMember(this.venueId, teamMember).subscribe(response => {
        this._toastr.success("Member is Updated Successfully");
        this._dialogRef.close();
      },
        (error) => {
          this._apiErrorService.ShowError(error);
        });
    }
    else {
      this._peopleService.AddTeamMember(this.venueId, teamMember).subscribe(response => {
        this._toastr.success("Member is added Successfully");
        this._dialogRef.close();
      },
        (error) => {
          this._apiErrorService.ShowError(error);
        });
    }
  }
}