import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { GooglemapService } from 'src/app/core/services/googlemap.service';
import { GetPolicyEvacuationDTO, PoliciesDetails } from 'src/app/Models/Customer';
import { PoliciesService } from "src/app/customer/components/policies/policies.service";
import { CustomerService } from "src/app/customer/customer.service";
import { DrawLinesAddFormComponent } from "../draw-lines-add-form/draw-lines-add-form.component";

@Component({
  selector: 'app-lockdown-add-edit-form',
  templateUrl: './lockdown-add-edit-form.component.html',
  styleUrls: ['./lockdown-add-edit-form.component.css']
})
export class LockdownAddEditFormComponent implements OnInit {
  DOOR_LOCK_MARKER_URL = "../assets/images/i-nvacuation-icon.png";
  LOCKABLE_DOOR_MARKER_URL = "../assets/images/lockdown-icon.png";
  venueId: string;
  assetId: string;
  lockdownRouteId: string;
  isEdit: boolean = false;
  lng: number;
  lat: number;
  zoom: number = 18;
  strokeColor = "#80cff4"
  isLockdownMapRequired: boolean = false;
  isLockdownMapLengthLimit: boolean = false;
  lockdownTabDetails = [];
  mainPerimeter = []
  assetData:any = []
  policyLockdownForm: FormGroup;
  GetPolicyLockdownInfo = new GetPolicyEvacuationDTO();

  windowLockData = []
  doorLockData = []

  constructor(
    private _dialogRef: MatDialogRef<LockdownAddEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private _apiErrorService: ApiErrorService,
    private _googleMapService: GooglemapService,
    private _policiesService: PoliciesService,
    private _customerService: CustomerService,
    private fb: FormBuilder,
    private _dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.venueId = this._initialInfo.dialogInfo.venueId
    this.isEdit = this._initialInfo.dialogInfo.isEdit
    this.assetId = this._initialInfo.dialogInfo.assetData.id
    this.assetData = this._initialInfo.dialogInfo.assetData
    this.InitForm();

    this.lat = this._initialInfo.dialogInfo.lat
    this.lng = this._initialInfo.dialogInfo.lng
    this.mainPerimeter = this._initialInfo.dialogInfo.mainPerimeter

    if (this.isEdit) {
      this.policyLockdownForm.get('lockdownDescription')?.setValue(this._initialInfo.dialogInfo.allInfo.description);
      this.lockdownTabDetails = this._initialInfo.dialogInfo.allInfo.routePoints;
      this.lockdownRouteId = this._initialInfo.dialogInfo.allInfo.id;
    }
  }

  InitForm() {
    this.policyLockdownForm = this.fb.group({
      lockdownDescription: [null, Validators.required],
      unableToLock: [null, Validators.required],
    });
  }

  get lockdownDescription() {
    return this.policyLockdownForm.get("lockdownDescription") as FormControl;
  }

  get unableToLock() {
    return this.policyLockdownForm.get("unableToLock") as FormControl;
  }

  numberToWord(n: number): string {
    return this._customerService.numberToWord(n);
  }

  resetForm(form: FormGroup, defaultValues?: any) {
    if (defaultValues) {
      form.reset(defaultValues);
    } else {
      form.reset();
    }
  }

  onUpdateWindowLockPin() {
    this._dialog
      .open(DrawLinesAddFormComponent, {
        height: "auto",
        width: "auto",
        data: {
          dialogInfo: {
            lat: this.lat,
            lng: this.lng,
            zoom: this.zoom,
            isPin: true,
            title: "Add window lock"
          }
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.windowLockData.push(data.localityPosition[0][0])
      });
  }

  onUpdateDoorLockPin() {
    this._dialog
      .open(DrawLinesAddFormComponent, {
        height: "auto",
        width: "auto",
        data: {
          dialogInfo: {
            lat: this.lat,
            lng: this.lng,
            zoom: this.zoom,
            isPin: true,
            title: "Add door lock"
          }
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.doorLockData.push(data.localityPosition[0][0])
      });
  }


  commanLockdownFormSubmission() {
    const newData = {
      localityPosition: [...this.GetPolicyLockdownInfo?.localityPosition],
      ...this.policyLockdownForm.value
    };
    
    if(this.GetPolicyLockdownInfo?.localityPosition?.length > 1){
      this.isLockdownMapRequired = true
      return false;
    }

    const payload = {
      id: this.venueId,
      description: newData.lockdownDescription,
      routePoints: this.isEdit ? this.lockdownTabDetails.length > 0 ? this.lockdownTabDetails : newData.localityPosition[0] : newData.localityPosition[0],
    } as PoliciesDetails

      // this._policiesService.UpdateLockdownDetails(payload, this.venueId, this.assetId, this.lockdownRouteId).subscribe(
      //   (response) => {
      //     if (response) {
      //       this.resetForm(this.policyLockdownForm);
      //       this._dialogRef.close(this.assetId);
      //     }
      //   },
      //   (error) => {
      //     this._apiErrorService.ShowError(error);
      //   }
      // );
    return true;
  }

  onSubmitPolicyLockdown() {
    if (this.policyLockdownForm.invalid || (this.lockdownTabDetails.length === 0 && !this.GetPolicyLockdownInfo?.localityPosition?.length)) {
      this.policyLockdownForm.markAllAsTouched();
      this.isLockdownMapRequired = !this.GetPolicyLockdownInfo?.localityPosition?.length;
      return false;
    }

    if(this.GetPolicyLockdownInfo?.localityPosition?.length > 1){
      this.isLockdownMapLengthLimit = true
      return false;
    }

    this.isLockdownMapRequired = false;
    this.isLockdownMapLengthLimit = false;
    if (!this.commanLockdownFormSubmission()) {
      return;
    }
  }
}

