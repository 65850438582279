import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/interceptors/api.service';
import { AssetsDetails, Markers } from 'src/app/Models/Customer';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  constructor( private _apiService: ApiService) { }

  InstanciateMainRedMarker(lat: number, lng: number, isVisible: boolean): Markers {
    const marker = new Markers();
    marker.lat = lat;
    marker.lng = lng;
    marker.label = "";
    marker.draggable = true;
    marker.visible = isVisible;
    marker.opacity = 0.7;
    marker.icon = "../../assets/images/red-marker.png";
    return marker;
  }

  CreatePointArrayForPolygon(path: any) {
    let points: { lat: number; lng: number }[] = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      points.push(path.getAt(i).toJSON());
    }
    return points;
  }

  calculateCentroid(points: { lat: number; lng: number }[]) {
    let totalLat = 0;
    let totalLng = 0;
    const numPoints = points.length;
  
    points.forEach(point => {
      totalLat += point.lat;
      totalLng += point.lng;
    });
  
    return {
      lat: totalLat / numPoints,
      lng: totalLng / numPoints
    };
  }

  AddAssetsDetails(details : AssetsDetails, id: string) {
    const url = `/Venue/Assets/Add/${id}`;
    return this._apiService.post(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetAssetsTabsOptions(categoryId: string) {
    const url = `/Venue/Assets/ListTypes/${categoryId}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetAssetsTabsDetails(venueId: string,categoryId: string) {
    const url = `/Venue/Assets/ListByCategory/${venueId}/${categoryId}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  UpdateAssetsTabsDetails(details: AssetsDetails,venueId: string,assetsId: string) {
    const url = `/Venue/Assets/Edit/${venueId}/${assetsId}`;
    return this._apiService.put(url, details).pipe(
      map((data) => {
        return data;
      })
    );
  }

  DeleteAssetsTabsDetails(venueId: string,assetsId: string) {
    const url = `/Venue/Assets/Delete/${venueId}/${assetsId}`;
    return this._apiService.put(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
