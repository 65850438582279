<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="custom-container">
                <div class="d-flex align-items-center mb-3 heading-section-with-icon">
                    <div class="heading-section">Edit Building & outbuilding
                    </div>
                    <img class="info-icon" src="../../assets/images/info-circle-icon.png" alt="info-circle-icon">
                </div>
                <div class="form-style w-400 ms-auto me-auto">
                    <form [formGroup]="assetsBuildingForm" (ngSubmit)="onSubmitAssetsBuilding()"
                        class="customer-signup-form">
                        <div class="fieldset">
                            <div class="main-form">
                                <div class="inner-main-form">
                                    <h5 class="mb-4">{{buildingTabName}}</h5>
                                    <div class="mb-4">
                                        <span class="num-circle">1</span>
                                        <div>Edit building type</div>
                                        <div class="select-style">
                                            <select class="form-control" formControlName="buildingType"
                                                class="w-100 select-box">
                                                <option [value]="option.value" *ngFor="let option of buildingOptions">
                                                    {{option.displayName}}</option>
                                            </select>
                                        </div>
                                        <span class="text-danger text-error-message"
                                            *ngIf="buildingType.touched && buildingType.hasError('required')">
                                            Building / outbuilding type is required
                                        </span>
                                        <div class="my-4">
                                            <input formControlName="buildingName" placeholder="Main building"
                                                class="form-control textarea-box" required />
                                            <span class="text-danger text-error-message"
                                                *ngIf="buildingName.touched && buildingName.hasError('required')">
                                                Name is required
                                            </span>
                                        </div>
                                        <div class="custom-feild">
                                            <div class="label">Number of floors.</div>
                                            <div class="select-style select-style-custom">
                                                <select class="form-control" formControlName="numberOfFloors"
                                                    class="w-100 select-box">
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                </select>
                                            </div>
                                        </div>
                                        <span class="text-danger text-error-message"
                                            *ngIf="numberOfFloors.touched && numberOfFloors.hasError('required')">
                                            Number Of Floors is required
                                        </span>
                                        <div class="custom-feild">
                                            <div class="label">Does the public have access to this building.</div>
                                            <div class="select-style select-style-custom-building">
                                                <select class="form-control" formControlName="buildingAccess"
                                                    class="w-100 select-box">
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-4">
                                        <span class="num-circle">2</span>
                                        <div>Edit description.</div>
                                        <textarea formControlName="buildingDescription"
                                            class="form-control textarea-box" rows="4"
                                            placeholder="e.g. Locked storage for gas for outside heating."
                                            required></textarea>
                                        <span class="text-danger text-error-message"
                                            *ngIf="buildingDescription.touched && buildingDescription.hasError('required')">
                                            Description is required
                                        </span>
                                    </div>
                                    <div class="mb-4">
                                        <span class="num-circle">3</span>
                                        <div>Edit the outline of the outbuilding.</div>
                                        <span class="text-danger text-error-message" *ngIf="isBuildingMapRequired">
                                            Map is required
                                        </span>

                                    </div>
                                </div>
                                <div class="modal map-modal-style show">
                                    <div class="w-100">
                                        <div class="map-iframe" *ngIf="!GetAssetsBuildingInfo.localityMapReinitial">
                                            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom"
                                                (mapReady)="OnMapReadyForBuilding($event)" [fullscreenControl]='true'
                                                [mapTypeControl]='true'>
                                                <agm-polygon *ngFor="let path of mainPerimeter" [paths]="path"
                                                    [strokeColor]='strokeColor' fillOpacity="transparent"
                                                    fillColor="transparent">
                                                </agm-polygon>
                                                <agm-polygon *ngFor="let path of
                                                    buildingTabDetails" [paths]="path">
                                                </agm-polygon>
                                            </agm-map>
                                        </div>
                                    </div>
                                </div>

                                <div class="inner-main-form mt-3">
                                    <div>
                                        <div class="label">Edit frequency</div>
                                        <div class="select-style">
                                            <select class="form-control" formControlName="frequency"
                                                class="w-100 select-box">
                                                <option value="1">Weekly</option>
                                                <option value="2">Monthly</option>
                                                <option value="4">Quarterly</option>
                                                <option value="5">6 Monthly</option>
                                                <option value="3">Annual</option>
                                            </select>
                                        </div>
                                    </div>
                                    <span class="text-danger text-error-message"
                                        *ngIf="frequency.touched && frequency.hasError('required')">
                                        Frequency is required
                                    </span>
                                </div>

                                <div class="next-btn inner-main-form">
                                    <div class="d-flex justify-content-between mt-4">
                                        <div>
                                            <button type="button" class="btn btn-danger text-end"
                                                (click)="RemoveBuildingPolygon()">Remove</button>
                                        </div>
                                        <div class="hs-submit text-start">
                                            <button type="submit" class="primary-btn">
                                                <div *ngIf="!isBuildingSend">
                                                    Save
                                                </div>
                                                <div *ngIf="isBuildingSend"
                                                    class="d-flex align-items-center justify-content-center">
                                                    <mat-spinner class="custom-button-spinner" diameter="20"></mat-spinner>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>