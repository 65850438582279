import { MemberProfileDetailsDTO } from './../../Models/Messages/MessageTemplateDTO';
import { AuthenticatedUserForGrouping } from './../../Models/Members/AuthenticatedUserGroups';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../interceptors/api.service';
import { Subject } from 'rxjs';
import { OrganizationGroup } from 'src/app/Models/BCURegionDTO.Model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  UserLoadedSubject = new Subject<boolean>();

  constructor(
    private _apiService: ApiService
    ) { }

  GetMemberProfile(id: string) {
    const url = `/account/profile/${id}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetCurrentUserProfileDetails(){
    const url = `/account/CurrentUserProfileDetails`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetCurrentUserSimpleProfileDetails(){
    const url = `/account/CurrentUserSimpleProfile`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
  
  SetAuthenticatedUserGroups(authenticatedUserGroups: AuthenticatedUserForGrouping){
    localStorage.setItem("AuthenticatedUserGroups", JSON.stringify(authenticatedUserGroups));
  }

  RemoveAuthenticatedUserGroups(key = "AuthenticatedUserGroups"){
    localStorage.removeItem(key);
  }

  GetAuthenticatedUserGroups(): AuthenticatedUserForGrouping | null{
    let obj = localStorage.getItem("AuthenticatedUserGroups");
    if (!obj){
      return null;
    }

    return  JSON.parse(obj) as AuthenticatedUserForGrouping;
  }

  GetAuthenticatedUserGroup(): OrganizationGroup{
    let obj = localStorage.getItem("AuthenticatedUserGroups");
    if (!obj){
      return null;
    }

    const authenticatedUserGroups = JSON.parse(obj) as AuthenticatedUserForGrouping;

    if (authenticatedUserGroups 
      && authenticatedUserGroups.region 
      && authenticatedUserGroups.region.groups 
      && authenticatedUserGroups.region.groups.length > 0 
      && authenticatedUserGroups.region.groups.find(x => x.isAuthenticatedUserGroup)){
        return authenticatedUserGroups.region.groups.find(x => x.isAuthenticatedUserGroup);
      }
    else{
      if (authenticatedUserGroups 
        && authenticatedUserGroups.region 
        && authenticatedUserGroups.region.groups 
        && authenticatedUserGroups.region.groups.length > 0 ){
          return authenticatedUserGroups.region.groups[0];
        }
        else{
          const obj = new OrganizationGroup();
          obj.isAuthenticatedUserGroup = false;
          obj.organizationGroupId = 1;
          obj.organizationGroupName = '';
          return obj;
        }
    }
  }


  CreateAuthenticatedUserGroupsInstance(member: MemberProfileDetailsDTO): AuthenticatedUserForGrouping{
    let authenticatedUserGroups = new AuthenticatedUserForGrouping();
    authenticatedUserGroups.id = member.id;
    authenticatedUserGroups.familyName = member.familyName;
    authenticatedUserGroups.firstName = member.firstName;

    authenticatedUserGroups.region = member.bcuRegion;

    // const groupedRegions = member.groups.reduce((acc, curr) => {
    //   if (!acc[curr.regionId]) {
    //     acc[curr.regionId] = [];
    //   }
    //   acc[curr.regionId].push(curr);
    //   return acc;
    // }, {});

    

    // for (const regionId in groupedRegions) {
    //   groupedRegions[regionId].forEach((obj, index) => {

    //     const group = obj as OrganizationGroupDTO;

    //     let regionForArray = new UserRegions();
    //     regionForArray.regionId = group.regionId;
    //     regionForArray.regionName = group.regionName;
    //     regionForArray.regionDescription = group.regionDescription;
    //     regionForArray.selected = index == 0 ? true : false;

    //     let newGroup = new UserGroups();
    //     newGroup.id = group.id;
    //     newGroup.groupName = group.groupName;
    //     newGroup.organizationId = group.organizationId;
    //     newGroup.organizationName = group.organizationName;

    //     regionForArray.groups.push(newGroup);

    //     authenticatedUserGroups.region.push(regionForArray);
    //   });
    // }

    return authenticatedUserGroups;
  }
}
