<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="custom-container">
                <div class="form-style w-400 ms-auto me-auto">
                    <form [formGroup]="bulkCheckForm" (ngSubmit)="onSubmitIndividualCheck()"
                        class="individual-check-form">
                        <div class="fieldset">
                            <div class="heading-section">Bulk training check
                            </div>
                            <p class="description">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing
                                elit, sed diam nonummy nibh euismod tincidunt ut
                            </p>
                            <div>
                                <div class="hs-input">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="select-style">
                                                <select class="form-control" formControlName="checkDay" required>
                                                    <option value="">DD</option>
                                                    <option [value]="day.id" *ngFor="let day of Days">{{day.name}}
                                                    </option>
                                                </select>
                                                <span class="text-danger text-error-message"
                                                    *ngIf="checkDay.touched && checkDay.hasError('required')">
                                                    Day is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="select-style">
                                                <select class="form-control" formControlName="checkMonth" required>
                                                    <option value="">MM</option>
                                                    <option [value]="month.id" *ngFor="let month of
                                              Months">{{month.name}}</option>
                                                </select>
                                                <span class="text-danger text-error-message"
                                                    *ngIf="checkMonth.touched && checkMonth.hasError('required')">
                                                    Month is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="select-style">
                                                <select class="form-control" formControlName="checkYear" required>
                                                    <option value="">YYYY</option>
                                                    <option [value]="year.id" *ngFor="let year of Years">{{year.name}}
                                                    </option>
                                                </select>
                                                <span class="text-danger text-error-message"
                                                    *ngIf="checkYear.touched && checkYear.hasError('required')">
                                                    Year is required
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="hs-input time-input">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <input type="time" class="form-control" formControlName="checkTime"
                                                required>
                                            <span class="text-danger text-error-message"
                                                *ngIf="checkTime.touched && checkTime.hasError('required')">
                                                Time is required
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="title">Training scenarios</div>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex justify-content-center ">
                                        <input type="checkbox" class="form-check-input" />
                                    </div>
                                    <div>Alerted of an attack</div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex justify-content-center ">
                                        <input type="checkbox" class="form-check-input" />
                                    </div>
                                    <div>Inside the building</div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex justify-content-center ">
                                        <input type="checkbox" class="form-check-input" />
                                    </div>
                                    <div>Suspicious bag</div>
                                </div>
                                <div class="text-danger text-error-message" *ngIf="listError">Atleast one asset is
                                    required</div>
                            </div>
                            <div class="mb-5">
                                <div class="title">Staff</div>
                                <div *ngFor="let type of filterData; let i = index" class="mb-1 d-flex">
                                    <div>
                                        {{type.firstName}}  {{type.givenName}}
                                    </div>
                                    <div class="cross-icon" (click)="removeTypeItem(type)">
                                        <img class="showHand" src="../../../assets/images/close-black-icon.png">
                                    </div>
                                </div>
                                <div class="text-danger text-error-message" *ngIf="listError">Atleast one asset is
                                    required</div>
                            </div>
                        </div>

                        <div class="next-btn">
                            <div class="d-flex flex-column justify-content-between">
                                <div class="hs-submit text-start">
                                    <button type="submit" class="primary-btn">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>