export enum RoomAssetsName {
  PrivateRoom = "PrivateRoom",
  PrivateArea = "PrivateArea",
  PublicArea = "PublicArea",
  Windows = "Windows",
  Exits = "Exits",
  StairsEscalators = "Stairs escalators",
  Lifts = "Lifts",
  FirstAidKits = "First aid kits",
  GrabBags = "Grab bags",
}

export enum RoomAssetsColor{
  PrivateRoom = "#f0ae7d",
  PrivateArea = "#f0ae7d",
  PublicArea = "#ffef88",
  Windows = "#e57332",
  Exits = "#06a255",
  StairsEscalators = "#c795be",
  Lifts = "#babcbe",
  FirstAidKits = "#babcbe",
  GrabBags = "#babcbe",
}
