import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiErrorService } from "src/app/core/services/api-error.service";
import { PoliciesService } from "src/app/customer/components/policies/policies.service";
import { CommunicationDetails } from "src/app/Models/Customer";

@Component({
  selector: 'app-scenario-add-edit-form',
  templateUrl: './scenario-add-edit-form.component.html',
  styleUrls: ['./scenario-add-edit-form.component.css']
})
export class ScenarioAddEditFormComponent implements OnInit {
  policyCommunicationForm: FormGroup;
  isEdit: boolean = false;
  venueId: string;
  communicationMethodId: string;

  constructor(
    private _dialogRef: MatDialogRef<ScenarioAddEditFormComponent>,
    private _policiesService: PoliciesService,
    private _apiErrorService: ApiErrorService,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.venueId = this._initialInfo.dialogInfo.venueId
    this.InitForm();

    this.isEdit = this._initialInfo.dialogInfo.isEdit
    if (this.isEdit) {
      this.policyCommunicationForm.get('communicationTitle')?.setValue(this._initialInfo.dialogInfo?.allInfo?.title);
      this.policyCommunicationForm.get('communicationDescription')?.setValue(this._initialInfo.dialogInfo?.allInfo?.description);
      this.policyCommunicationForm.get('actions')?.setValue(this._initialInfo.dialogInfo?.allInfo?.description);
      this.communicationMethodId = this._initialInfo.dialogInfo?.allInfo?.id
    }
  }

  InitForm() {
    this.policyCommunicationForm = this.fb.group({
      communicationTitle: [null, Validators.required],
      communicationDescription: [null, Validators.required],
      actions:[null, Validators.required],
    });
  }

  get communicationTitle() {
    return this.policyCommunicationForm.get("communicationTitle") as FormControl;
  }

  get communicationDescription() {
    return this.policyCommunicationForm.get("communicationDescription") as FormControl;
  }

  get actions() {
    return this.policyCommunicationForm.get("actions") as FormControl;
  }

  resetForm(form: FormGroup, defaultValues?: any) {
    if (defaultValues) {
      form.reset(defaultValues);
    } else {
      form.reset();
    }
  }

  commanBuildingFormSubmission() {
    const newData = {
      ...this.policyCommunicationForm.value
    };

    let payload = {
      id: this.venueId,
      title: newData.communicationTitle,
      description: newData.communicationDescription,
      actions: newData.actions
    } as CommunicationDetails

    if (this.isEdit) {

      this._policiesService.UpdateCommunicationDetails(payload, this.venueId, this.communicationMethodId).subscribe(
        (response) => {
          if (response) {
            this.resetForm(this.policyCommunicationForm);
            this._dialogRef.close(newData);
          }
        },
        (error) => {
          this._apiErrorService.ShowError(error);
        }
      );

    } else {
      this._policiesService.AddCommunicationDetails(payload, this.venueId).subscribe(
        (response) => {
          if (response) {
            this.resetForm(this.policyCommunicationForm);
            this._dialogRef.close(newData);
          }
        },
        (error) => {
          this._apiErrorService.ShowError(error);
        }
      );
    }
    return true;
  }

  onSubmitpolicyCommunication() {
    if (this.policyCommunicationForm.invalid) {
      this.policyCommunicationForm.markAllAsTouched();
      return false;
    }

    if (!this.commanBuildingFormSubmission()) {
      return;
    }
  }
}

