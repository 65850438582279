import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CognitoService } from "src/app/Authentication/cognito.service";
import { AppStartService } from "src/app/core/services/app-start.service";

/**
 * Prevent access to routes if access-token is not present.
 * 
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable()
export class UserAuthGuard implements CanActivate {
  constructor(
    private _cognitoService: CognitoService,
    private _appStartService: AppStartService
    ) { }

  async canActivate(
    next: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): Promise<boolean> {

      if (!this._appStartService.IsCognitoUserLoaded){
        return await this._cognitoService.IsAuthenticatedForUser();
      }
      return await this._cognitoService.IsUser(this._appStartService.currentCognitoUser.attributes['custom:roles']);
  }
}
